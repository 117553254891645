var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { "open-on-hover": "", "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function({ on }) {
            return [
              _c("v-btn", _vm._g({ attrs: { text: "" } }, on), [
                _c("div", { staticClass: "d-none d-sm-flex" }, [
                  _vm._v(
                    " " + _vm._s(_vm.user.username || "Default Name") + " "
                  )
                ])
              ])
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                { attrs: { to: "/admin/accountdetail" } },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.user.username || "Default Name"))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          return _vm.onSignout()
                        }
                      }
                    },
                    [_vm._v("Đăng xuất")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }