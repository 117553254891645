var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      staticClass: "elevation-2",
      attrs: {
        color: "white",
        "max-height": "80px",
        height: _vm.getBarHeight,
        app: "",
        dark: ""
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-start align-center",
          class: {
            "px-4 md-height": _vm.$vuetify.breakpoint.mdAndUp
          }
        },
        [
          _c("v-img", {
            staticClass: "mr-2",
            attrs: {
              alt: "Vuetify Logo",
              contain: "",
              height: "100%",
              src: require("@/assets/homepage/logo-tqb.svg"),
              transition: "scale-transition"
            }
          })
        ],
        1
      ),
      _c("v-spacer"),
      _c(
        "div",
        { staticClass: "display-guest-toolbar" },
        [
          _vm.isGuestBar
            ? _c("GuestToolbar", { attrs: { isOpen: true } })
            : _vm._e(),
          !_vm.isGuestBar
            ? _c("UserToolbar", { attrs: { isOpen: true } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }