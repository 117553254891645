var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "480px", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("div", { staticClass: "title--text" }, [_vm._v("Đăng nhập")]),
              _c("v-spacer"),
              _c(
                "v-icon",
                {
                  staticClass: "mr-n1",
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("mdi-close")]
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "pt-4 pb-2" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.isValid,
                    callback: function($$v) {
                      _vm.isValid = $$v
                    },
                    expression: "isValid"
                  }
                },
                [
                  _c("div", { staticClass: "text-subtitle-1" }, [
                    _vm._v(
                      " Mã học sinh (của Bộ GD&ĐT, trên phần mềm CSDL ngành)"
                    ),
                    _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")])
                  ]),
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: "##########",
                        expression: "'##########'"
                      }
                    ],
                    attrs: {
                      placeholder:
                        "Nhập mã định danh tại đây (Ví dụ: 1231231231)",
                      name: "login",
                      type: "tel",
                      color: "primary",
                      outlined: "",
                      "validate-on-blur": ""
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.submit.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.credentials.identifier,
                      callback: function($$v) {
                        _vm.$set(_vm.credentials, "identifier", $$v)
                      },
                      expression: "credentials.identifier"
                    }
                  }),
                  _c("div", { staticClass: "text-subtitle-1" }, [
                    _vm._v("Mật khẩu "),
                    _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")])
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      placeholder: "Nhập mật khẩu tại đây",
                      type: _vm.showPassword ? "text" : "password",
                      "append-icon": _vm.showPassword
                        ? "mdi-eye"
                        : "mdi-eye-off",
                      rules: _vm.passwordRules,
                      color: "primary",
                      outlined: "",
                      "validate-on-blur": ""
                    },
                    on: {
                      "click:append": function($event) {
                        _vm.showPassword = !_vm.showPassword
                      },
                      keyup: [
                        function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.submit.apply(null, arguments)
                        },
                        function($event) {
                          return _vm.$refs.form.validate()
                        }
                      ]
                    },
                    model: {
                      value: _vm.credentials.password,
                      callback: function($$v) {
                        _vm.$set(_vm.credentials, "password", $$v)
                      },
                      expression: "credentials.password"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "white--text text-subtitle-1 text-none mt-6",
                  staticStyle: { width: "100%" },
                  attrs: {
                    depressed: "",
                    "x-large": "",
                    color: "primary",
                    disabled: !_vm.isValid,
                    loading: _vm.loading
                  },
                  on: {
                    click: function($event) {
                      return _vm.submit()
                    }
                  }
                },
                [_vm._v("Đăng nhập ")]
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-center mt-6" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "info--text text-subtitle-1 text-decoration-underline text-none",
                      staticStyle: { width: "100%" },
                      attrs: { plain: "" },
                      on: {
                        click: function($event) {
                          return _vm.resetPassword()
                        }
                      }
                    },
                    [_vm._v("Quên mật khẩu? ")]
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-subtitle-1 font-weight-bold text-none mt-3",
                  staticStyle: { width: "100%" },
                  attrs: { plain: "", color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.register()
                    }
                  }
                },
                [_vm._v("Chưa có tài khoản? Đăng ký ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }