import {RoomSubject} from '@/plugins/api'
import alert from '@/plugins/alert'

/* eslint-disable no-unused-vars */
export default {
  namespaced: true,
  actions: {
    async fetchRoomSubjects({commit}, options) {
      const roomSubs = await RoomSubject.fetch({...options})
      return roomSubs
    },
    async fetchRoomSubject({commit}, id) {
      const roomSub = await RoomSubject.fetchOne(id)
      return roomSub
    },
    async updateRoomSubject({commit}, {id, ...data}) {
      const updatedRoomSub = await RoomSubject.update(id, {...data})
      return updatedRoomSub
    },
    async countRoomSubjects({commit}, options) {
      try {
        return await RoomSubject.count(options)
      } catch (e) {
        alert.error(e)
      }
    }
  }
}
/* eslint-disable no-unused-vars */
