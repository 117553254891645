const STEP_DATA_GRADE_10 = [
  {
    title: 'Khai đơn xét tuyển - Mẫu số 01',
    key: 'fill-info',
    step: 1
  },
  {
    title: 'Kết quả khảo sát ĐGNL (hệ tiên tiến)',
    key: 'register-expectation',
    step: 2
  },
  {
    title: 'Khai đơn xét tuyển - Mẫu số 02',
    key: 'register-group-expectation',
    step: 3
  },
  {
    title: 'Nhập học trực tuyến',
    key: 'study-result',
    step: 4
  },
  {
    title: 'Nộp hồ sơ gốc',
    key: 'exam-result',
    step: 5
  }
]

const STEP_DATA_GRADE_6 = [
  {
    title: 'Khai đơn xét tuyển',
    key: 'fill-info',
    step: 1
  },
  {
    title: 'Kết quả xét tuyển',
    key: 'register-expectation',
    step: 2
  },
  {
    title: 'Nhập học trực tuyến',
    key: 'register-group-expectation',
    step: 3
  },
  {
    title: 'Nộp hồ sơ gốc',
    key: 'register-group-expectation',
    step: 4
  }
]
const STEP_DATA_GRADE_10_SUBMIT = [
  {
    title: 'Thông tin nhập học',
    key: 'fill-info',
    step: 1
  },
  {
    title: 'Thu hồ sơ gốc',
    key: 'register-expectation',
    step: 2
  },
  {
    title: 'Nhóm môn học',
    key: 'register-group-expectation',
    step: 3
  },
  {
    title: 'Dịch vụ',
    key: 'study-result',
    step: 4
  },
  {
    title: 'Thu tiền',
    key: 'exam-result',
    step: 5
  },
  {
    title: 'Lịch sử chỉnh sửa',
    key: 'logs',
    step: 6
  }
]

const STEP_DATA_GRADE_6_SUBMIT = [
  {
    title: 'Thông tin nhập học',
    key: 'fill-info',
    step: 1
  },
  {
    title: 'Thu hồ sơ gốc',
    key: 'register-expectation',
    step: 2
  },
  {
    title: 'Dịch vụ',
    key: 'register-group-expectation',
    step: 3
  },
  {
    title: 'Thu tiền',
    key: 'register-group-expectation',
    step: 4
  },
  {
    title: 'Lịch sử chỉnh sửa',
    key: 'logs',
    step: 5
  }
]
export default {
  STEP_DATA_GRADE_10,
  STEP_DATA_GRADE_6,
  STEP_DATA_GRADE_10_SUBMIT,
  STEP_DATA_GRADE_6_SUBMIT
}
