var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "400px" },
      model: {
        value: _vm.dialog.state,
        callback: function($$v) {
          _vm.$set(_vm.dialog, "state", $$v)
        },
        expression: "dialog.state"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-6" },
        [
          _c(
            "v-card-title",
            { staticClass: "pa-0 title--text card-title mb-6" },
            [_vm._v(_vm._s(_vm.dialog.title)), _c("v-spacer")],
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass: "card-content mx-auto pa-0",
              staticStyle: { "max-width": "450px" }
            },
            [
              _c("div", {
                staticClass: "mb-4",
                staticStyle: { "white-space": "pre-line" },
                attrs: { name: "top-content" },
                domProps: { innerHTML: _vm._s(_vm.dialog.topContent) }
              }),
              _c("div", {
                staticClass: "mb-4",
                attrs: { name: "mid-content" },
                domProps: { innerHTML: _vm._s(_vm.dialog.midContent) }
              }),
              _c("div", {
                staticClass: "mb-6",
                attrs: { name: "bottom-content" },
                domProps: { innerHTML: _vm._s(_vm.dialog.botContent) }
              })
            ]
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-space-between pa-0" },
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.dialog.hideCancel,
                      expression: "!dialog.hideCancel"
                    }
                  ],
                  staticClass: "px-6 btn text-none",
                  attrs: {
                    small: "",
                    color: "primary",
                    large: "",
                    outlined: "",
                    dense: ""
                  },
                  on: { click: _vm.cancel }
                },
                [_vm._v(_vm._s(_vm.dialog.cancelText))]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.dialog.hideOk,
                      expression: "!dialog.hideOk"
                    }
                  ],
                  staticClass: "px-6 btn text-none elevation-0",
                  attrs: { small: "", color: "primary", large: "", dense: "" },
                  on: { click: _vm.done }
                },
                [_vm._v(_vm._s(_vm.dialog.okText))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }