/* eslint-disable vue/valid-v-slot */
import alert from '@/plugins/alert'
import {Teacher, User} from '@/plugins/api'
/* eslint-disable no-unused-vars */

export default {
  namespaced: true,
  state: {
    teacher: {},
    avatar: {},
    teachings: [],
    ownClasses: [],
    teacherUsername: '',
    teachingClassCount: 0
  },
  actions: {
    async fetchOneTeacher({commit, state}, id) {
      const teacher = await Teacher.fetchOne(id)
      return teacher
    },
    async fetchTeacher({commit}, teacherId) {
      commit('setTeacher', null)
      const teacher = await Teacher.fetchOne(teacherId)
      commit('setTeacher', teacher)
    },
    async removeTeacher({commit}, teacher) {
      if (teacher.user) {
        await User.remove(teacher.user)
      }
      await Teacher.remove(teacher.id)
      commit('removeTeacher', teacher.id)
      alert.success('Xóa thành công!')
    },
    async updateTeacher({commit}, {id, ...teacher}) {
      const updatedTeacher = await Teacher.update(id, teacher)
      commit('setTeacher', updatedTeacher)
      alert.success('Cập nhật thành công!')
      return updatedTeacher
    },

    setTeacherUsername({commit}, teacherUsername) {
      commit('changeState', {teacherUsername})
    }
  },
  mutations: {
    setTeacher(state, teacher) {
      state.teacher = teacher
    },
    removeTeacher(state, teacherId) {
      state.teacher = null
    },
    setAvatar(state, avatar) {
      state.teacher = {
        ...state.teacher,
        avatar: avatar
      }
      state.avatar = avatar
    },
    updateTeachings(state, id) {
      state.teachings = state.teachings.filter(t => t.id !== id)
    },
    updateOwnClassList(state, classId) {
      state.ownClasses = state.ownClasses.filter(c => c.id !== classId)
    }
  },
  getters: {
    avatar: state => {
      return state.avatar
    }
  }
}
