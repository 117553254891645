<template>
  <v-navigation-drawer
    v-model="state"
    class="elevation-1"
    app
    :permanent="$vuetify.breakpoint.smAndUp"
    :temporary="$vuetify.breakpoint.xs"
  >
    <div
      class="d-flex align-center px-6"
      :class="{
        'mobile-drawer-logo': $vuetify.breakpoint.xs,
        'px-7': $vuetify.breakpoint.xs
      }"
    >
      <div class="d-flex justify-center pl-0">
        <v-img height="64px" src="../../assets/logo-tqb.svg" contain position="center"></v-img>
      </div>
    </div>
    <v-divider></v-divider>
    <v-list class="py-0">
      <!-- Enroll -->
      <div v-if="$auth.policies['view-enroll'] || $auth.policies['view-document-submit']">
        <v-list-item to="/admin/dashboard" active-class="active-item" link v-if="$auth.policies['view-enroll']">
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Báo cáo </v-list-item-title>
        </v-list-item>
        <v-list-item
          to="/admin/user"
          active-class="active-item"
          v-if="user.role && user.role.type === 'admin' && user.department === 'both'"
          link
        >
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Tài khoản phụ huynh</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item to="/admin/active-code" active-class="active-item" link>
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-folder</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Mã hồ sơ tuyển sinh</v-list-item-title>
        </v-list-item> -->
        <v-list-item to="/admin/document" active-class="active-item" link v-if="$auth.policies['view-enroll']">
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-account-box-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Hồ sơ tuyển sinh</v-list-item-title>
        </v-list-item>
        <v-list-item to="/admin/document-cancel" active-class="active-item" link v-if="$auth.policies['view-enroll']">
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-account-box-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Hồ sơ đã hủy</v-list-item-title>
        </v-list-item>
        <v-list-item
          to="/admin/submit-document"
          active-class="active-item"
          link
          v-if="$auth.policies['view-document-submit']"
        >
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-account-box-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Nộp hồ sơ gốc</v-list-item-title>
        </v-list-item>
        <v-list-item
          to="/admin/grade6-enroll-forms"
          active-class="active-item"
          link
          v-if="$auth.policies['view-enroll']"
        >
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-file-document-edit-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Đơn đăng ký khối 6</v-list-item-title>
        </v-list-item>
        <v-list-item
          to="/admin/grade10-enroll-forms"
          active-class="active-item"
          link
          v-if="$auth.policies['view-enroll']"
        >
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-file-document-edit-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Đơn đăng ký khối 10</v-list-item-title>
        </v-list-item>
        <v-list-item to="/admin/grade6-result" active-class="active-item" link v-if="$auth.policies['view-enroll']">
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-note-text</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Kết quả thi Khối 6 </v-list-item-title>
        </v-list-item>
        <v-list-item to="/admin/grade10-result" active-class="active-item" link v-if="$auth.policies['view-enroll']">
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-note-text</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Kết quả thi Khối 10 </v-list-item-title>
        </v-list-item>
        <v-list-item to="/admin/notification" active-class="active-item" link v-if="$auth.policies['view-enroll']">
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-bell</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Gửi thông báo </v-list-item-title>
        </v-list-item>
      </div>
      <!-- Exam -->
      <v-divider></v-divider>
      <div v-if="$auth.policies['view-exam']">
        <v-list-item active-class="active-item" link to="/admin/exams" v-if="$auth.policies['manage-exam']">
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-school</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Danh sách kỳ thi</v-list-item-title>
        </v-list-item>
        <v-list-item active-class="active-item" link to="/admin/students" v-if="$auth.policies['manage-exam']">
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Danh sách học sinh</v-list-item-title>
        </v-list-item>
        <v-list-item active-class="active-item" link to="/admin/absent" v-if="$auth.policies['manage-exam']">
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-file-document-edit-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Biên bản thi</v-list-item-title>
        </v-list-item>
        <v-list-item active-class="active-item" link to="/admin/code" v-if="$auth.policies['manage-exam']">
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-numeric</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Số túi và mã phách</v-list-item-title>
        </v-list-item>
        <v-list-item active-class="active-item" link to="/admin/markInput">
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-pencil-box-multiple-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Nhập điểm thi</v-list-item-title>
        </v-list-item>
        <v-list-item active-class="active-item" link to="/admin/markView" v-if="$auth.policies['manage-exam']">
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-ab-testing</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Xem điểm thi</v-list-item-title>
        </v-list-item>
        <v-list-item active-class="active-item" link to="/admin/teachers" v-if="$auth.policies['manage-exam']">
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-account-multiple-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Danh sách giáo viên</v-list-item-title>
        </v-list-item>
        <v-list-item active-class="active-item" link to="/admin/teacherAssign" v-if="$auth.policies['manage-exam']">
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-account-supervisor-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Phân công chấm thi</v-list-item-title>
        </v-list-item>
        <v-list-item active-class="active-item" link to="/admin/monitorAssign" v-if="$auth.policies['manage-exam']">
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-account-supervisor-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Phân công giám thị</v-list-item-title>
        </v-list-item>
      </div>
      <v-divider></v-divider>
      <!-- CRM -->
      <div v-if="$auth.policies['view-crm']">
        <v-list-item active-class="active-item" link to="/admin/sale/" v-if="$auth.policies['view-sale-user']">
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Nhân viên sale</v-list-item-title>
        </v-list-item>
        <v-list-item active-class="active-item" link to="/admin/lead">
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-filter-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Khách hàng tiềm năng</v-list-item-title>
        </v-list-item>
        <v-list-item active-class="active-item" link to="/admin/product" v-if="$auth.policies['view-product']">
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-package-variant-closed</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Hệ đào tạo</v-list-item-title>
        </v-list-item>
        <v-list-item active-class="active-item" link to="/admin/report">
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-chart-bar</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Báo cáo CRM</v-list-item-title>
        </v-list-item>
        <v-list-item active-class="active-item" link to="/admin/sms">
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-message-processing</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Tin nhắn SMS</v-list-item-title>
        </v-list-item>
        <v-list-item active-class="active-item" link to="/admin/email">
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-email-outline </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Email</v-list-item-title>
        </v-list-item>
      </div>

      <v-divider></v-divider>
      <!-- Setting -->
      <div v-if="$auth.policies['policies-setting']">
        <v-list-item active-class="active-item" link to="/admin/policies">
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-account-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Phân quyền</v-list-item-title>
        </v-list-item>
      </div>
    </v-list>

    <div id="notice" v-if="isDevelopmentBuild">
      <div class="error--text text-subtitle-1">Development Build. v{{ version }}</div>
    </div>
  </v-navigation-drawer>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
  props: {
    drawer: {type: Boolean}
  },
  created() {
    this.version = process.env.VUE_APP_API_VERSION
    this.isDevelopmentBuild = process.env.NODE_ENV === 'development'
  },
  data() {
    return {
      state: false,
      isDevelopmentBuild: true,
      version: '0.1'
    }
  },
  watch: {
    drawer() {
      this.state = true
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '32px'
        default:
          return '64px'
      }
    }
  }
}
</script>

<style scoped>
.mobile-drawer-logo {
  height: 64px;
}
.active-item {
  background: #0d47a1;
  color: white !important;
}
#notice {
  position: absolute;
  bottom: 12px;
  left: 12px;
}
</style>
