/* eslint-disable no-unused-vars */
import alert from '@/plugins/alert'
import { Notification } from '@/plugins/api'
export default {
  namespaced: true,
  state: {
    notis: {}
  },
  actions: {
    async fetchNotis({ commit }, options) {
      try {
        const notis = await Notification.fetch({ ...options, _limit: -1, _sort: 'createdAt:desc' })
        commit('setNotis', notis)
        return notis
      } catch (e) {
        alert.error(e)
      }
    },
    async countNotis({ commit }, options) {
      try {
        return await Notification.count(options)
      } catch (e) {
        alert.error(e)
      }
    },
    async createNoti({ commit }, data) {
      try {
        const newNoti = await Notification.create(data)
        commit('setNoti', newNoti)
        alert.success('Tạo thông báo thành công!')
        return newNoti
      } catch (e) {
        alert.error(e)
      }
    },
    setNotification({ commit, state }, noti) {
      commit('setNoti', noti)
    },
    async removeNoti({ dispatch }, itemId) {
      await dispatch('removeNoti', itemId)
    },
    setNotis({ commit }, notis) {
      commit('setNotis', notis)
    }
  },
  mutations: {
    setNotis(state, notis) {
      state.notis = notis.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [`${currentValue.id}`]: currentValue,
        }),
        {}
      )
      console.log(state.notis)
    },
    setNoti(state, noti) {
      state.notis = {
        [noti.id]: noti,
        ...state.notis,
      }
    },
    removeNoti(state, id) {
      delete state.noti[id]
      state.noti = { ...state.noti }
    },
  },
  getters: {
    notis: (state) => {
      return Object.values(state.notis)
    },
    noti: (state) => (id) => {
      return state.notis[id]
    },
    count: (state) => {
      return state.count
    },
  },
}
/* eslint-enable no-unused-vars */