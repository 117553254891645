import {Mark} from '@/plugins/api'
import alert from '@/plugins/alert'

/* eslint-disable no-unused-vars */
export default {
  namespaced: true,
  actions: {
    async fetchMarks({commit}, options) {
      const marks = await Mark.fetch({...options})
      return marks
    },
    async fetchMark({commit}, id) {
      const mark = await Mark.fetchOne(id)
      return mark
    },
    async updateMark({commit}, {id, ...data}) {
      const updatedMark = await Mark.update(id, {...data})
      return updatedMark
    },
    async countMarks({commit}, options) {
      try {
        return await Mark.count(options)
      } catch (e) {
        alert.error(e)
      }
    }
  }
}
/* eslint-disable no-unused-vars */
