var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "elevation-1",
      attrs: {
        app: "",
        permanent: _vm.$vuetify.breakpoint.smAndUp,
        temporary: _vm.$vuetify.breakpoint.xs
      },
      model: {
        value: _vm.state,
        callback: function($$v) {
          _vm.state = $$v
        },
        expression: "state"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-center px-6",
          class: {
            "mobile-drawer-logo": _vm.$vuetify.breakpoint.xs,
            "px-7": _vm.$vuetify.breakpoint.xs
          }
        },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-center pl-0" },
            [
              _c("v-img", {
                attrs: {
                  height: "64px",
                  src: require("../../assets/logo-tqb.svg"),
                  contain: "",
                  position: "center"
                }
              })
            ],
            1
          )
        ]
      ),
      _c("v-divider"),
      _c(
        "v-list",
        { staticClass: "py-0" },
        [
          _vm.$auth.policies["view-enroll"] ||
          _vm.$auth.policies["view-document-submit"]
            ? _c(
                "div",
                [
                  _vm.$auth.policies["view-enroll"]
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            to: "/admin/dashboard",
                            "active-class": "active-item",
                            link: ""
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-4" },
                            [_c("v-icon", [_vm._v("mdi-view-dashboard")])],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v(" Báo cáo ")])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.user.role &&
                  _vm.user.role.type === "admin" &&
                  _vm.user.department === "both"
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            to: "/admin/user",
                            "active-class": "active-item",
                            link: ""
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-4" },
                            [_c("v-icon", [_vm._v("mdi-account")])],
                            1
                          ),
                          _c("v-list-item-title", [
                            _vm._v("Tài khoản phụ huynh")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$auth.policies["view-enroll"]
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            to: "/admin/document",
                            "active-class": "active-item",
                            link: ""
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-4" },
                            [_c("v-icon", [_vm._v("mdi-account-box-outline")])],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Hồ sơ tuyển sinh")])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$auth.policies["view-enroll"]
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            to: "/admin/document-cancel",
                            "active-class": "active-item",
                            link: ""
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-4" },
                            [_c("v-icon", [_vm._v("mdi-account-box-outline")])],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Hồ sơ đã hủy")])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$auth.policies["view-document-submit"]
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            to: "/admin/submit-document",
                            "active-class": "active-item",
                            link: ""
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-4" },
                            [_c("v-icon", [_vm._v("mdi-account-box-outline")])],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Nộp hồ sơ gốc")])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$auth.policies["view-enroll"]
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            to: "/admin/grade6-enroll-forms",
                            "active-class": "active-item",
                            link: ""
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-4" },
                            [
                              _c("v-icon", [
                                _vm._v("mdi-file-document-edit-outline")
                              ])
                            ],
                            1
                          ),
                          _c("v-list-item-title", [
                            _vm._v("Đơn đăng ký khối 6")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$auth.policies["view-enroll"]
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            to: "/admin/grade10-enroll-forms",
                            "active-class": "active-item",
                            link: ""
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-4" },
                            [
                              _c("v-icon", [
                                _vm._v("mdi-file-document-edit-outline")
                              ])
                            ],
                            1
                          ),
                          _c("v-list-item-title", [
                            _vm._v("Đơn đăng ký khối 10")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$auth.policies["view-enroll"]
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            to: "/admin/grade6-result",
                            "active-class": "active-item",
                            link: ""
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-4" },
                            [_c("v-icon", [_vm._v("mdi-note-text")])],
                            1
                          ),
                          _c("v-list-item-title", [
                            _vm._v(" Kết quả thi Khối 6 ")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$auth.policies["view-enroll"]
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            to: "/admin/grade10-result",
                            "active-class": "active-item",
                            link: ""
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-4" },
                            [_c("v-icon", [_vm._v("mdi-note-text")])],
                            1
                          ),
                          _c("v-list-item-title", [
                            _vm._v(" Kết quả thi Khối 10 ")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$auth.policies["view-enroll"]
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            to: "/admin/notification",
                            "active-class": "active-item",
                            link: ""
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-4" },
                            [_c("v-icon", [_vm._v("mdi-bell")])],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v(" Gửi thông báo ")])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c("v-divider"),
          _vm.$auth.policies["view-exam"]
            ? _c(
                "div",
                [
                  _vm.$auth.policies["manage-exam"]
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            "active-class": "active-item",
                            link: "",
                            to: "/admin/exams"
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-4" },
                            [_c("v-icon", [_vm._v("mdi-school")])],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Danh sách kỳ thi")])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$auth.policies["manage-exam"]
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            "active-class": "active-item",
                            link: "",
                            to: "/admin/students"
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-4" },
                            [_c("v-icon", [_vm._v("mdi-account-multiple")])],
                            1
                          ),
                          _c("v-list-item-title", [
                            _vm._v("Danh sách học sinh")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$auth.policies["manage-exam"]
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            "active-class": "active-item",
                            link: "",
                            to: "/admin/absent"
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-4" },
                            [
                              _c("v-icon", [
                                _vm._v("mdi-file-document-edit-outline")
                              ])
                            ],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Biên bản thi")])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$auth.policies["manage-exam"]
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            "active-class": "active-item",
                            link: "",
                            to: "/admin/code"
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-4" },
                            [_c("v-icon", [_vm._v("mdi-numeric")])],
                            1
                          ),
                          _c("v-list-item-title", [
                            _vm._v("Số túi và mã phách")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        "active-class": "active-item",
                        link: "",
                        to: "/admin/markInput"
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "mr-4" },
                        [
                          _c("v-icon", [
                            _vm._v("mdi-pencil-box-multiple-outline")
                          ])
                        ],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Nhập điểm thi")])
                    ],
                    1
                  ),
                  _vm.$auth.policies["manage-exam"]
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            "active-class": "active-item",
                            link: "",
                            to: "/admin/markView"
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-4" },
                            [_c("v-icon", [_vm._v("mdi-ab-testing")])],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Xem điểm thi")])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$auth.policies["manage-exam"]
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            "active-class": "active-item",
                            link: "",
                            to: "/admin/teachers"
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-4" },
                            [
                              _c("v-icon", [
                                _vm._v("mdi-account-multiple-outline")
                              ])
                            ],
                            1
                          ),
                          _c("v-list-item-title", [
                            _vm._v("Danh sách giáo viên")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$auth.policies["manage-exam"]
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            "active-class": "active-item",
                            link: "",
                            to: "/admin/teacherAssign"
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-4" },
                            [
                              _c("v-icon", [
                                _vm._v("mdi-account-supervisor-circle-outline")
                              ])
                            ],
                            1
                          ),
                          _c("v-list-item-title", [
                            _vm._v("Phân công chấm thi")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$auth.policies["manage-exam"]
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            "active-class": "active-item",
                            link: "",
                            to: "/admin/monitorAssign"
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-4" },
                            [
                              _c("v-icon", [
                                _vm._v("mdi-account-supervisor-circle-outline")
                              ])
                            ],
                            1
                          ),
                          _c("v-list-item-title", [
                            _vm._v("Phân công giám thị")
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c("v-divider"),
          _vm.$auth.policies["view-crm"]
            ? _c(
                "div",
                [
                  _vm.$auth.policies["view-sale-user"]
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            "active-class": "active-item",
                            link: "",
                            to: "/admin/sale/"
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-4" },
                            [_c("v-icon", [_vm._v("mdi-account-group")])],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Nhân viên sale")])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        "active-class": "active-item",
                        link: "",
                        to: "/admin/lead"
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "mr-4" },
                        [_c("v-icon", [_vm._v("mdi-filter-variant")])],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Khách hàng tiềm năng")])
                    ],
                    1
                  ),
                  _vm.$auth.policies["view-product"]
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            "active-class": "active-item",
                            link: "",
                            to: "/admin/product"
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-4" },
                            [
                              _c("v-icon", [
                                _vm._v("mdi-package-variant-closed")
                              ])
                            ],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Hệ đào tạo")])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        "active-class": "active-item",
                        link: "",
                        to: "/admin/report"
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "mr-4" },
                        [_c("v-icon", [_vm._v("mdi-chart-bar")])],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Báo cáo CRM")])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        "active-class": "active-item",
                        link: "",
                        to: "/admin/sms"
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "mr-4" },
                        [_c("v-icon", [_vm._v("mdi-message-processing")])],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Tin nhắn SMS")])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        "active-class": "active-item",
                        link: "",
                        to: "/admin/email"
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "mr-4" },
                        [_c("v-icon", [_vm._v("mdi-email-outline ")])],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Email")])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c("v-divider"),
          _vm.$auth.policies["policies-setting"]
            ? _c(
                "div",
                [
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        "active-class": "active-item",
                        link: "",
                        to: "/admin/policies"
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "mr-4" },
                        [_c("v-icon", [_vm._v("mdi-account-cog")])],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Phân quyền")])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.isDevelopmentBuild
        ? _c("div", { attrs: { id: "notice" } }, [
            _c("div", { staticClass: "error--text text-subtitle-1" }, [
              _vm._v("Development Build. v" + _vm._s(_vm.version))
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }