import {Policies} from '@/plugins/api'

export default {
  namespaced: true,
  state: {
    policies: []
  },
  actions: {
    async fetchPolicies({commit}, params) {
      try {
        const policies = await Policies.fetch({...params})
        commit('setPolicies', policies)
      } catch (error) {
        console.error(error)
      }
    }
  },
  mutations: {
    setPolicies(state, policies) {
      state.policies = policies
    }
  }
}
