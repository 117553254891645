import {MonitorExam} from '@/plugins/api'
import alert from '@/plugins/alert'

/* eslint-disable no-unused-vars */
export default {
  namespaced: true,
  actions: {
    async fetchMonitorExams({commit}, options) {
      const monitorExams = await MonitorExam.fetch({...options})
      return monitorExams
    },
    async fetchMonitorExam({commit}, id) {
      const monitorExam = await MonitorExam.fetchOne(id)
      return monitorExam
    },
    async createMonitorExam({commit}, data) {
      const newMonEx = await MonitorExam.create(data)
      return newMonEx
    },
    async updateMonitorExam({commit}, {id, ...data}) {
      const updatedMonEx = await MonitorExam.update(id, {...data})
      return updatedMonEx
    },
    async countMonitorExams({commit}, options) {
      try {
        return await MonitorExam.count(options)
      } catch (e) {
        alert.error(e)
      }
    },
    async removeMonitorExams({commit}, id) {
      await MonitorExam.remove(id)
      alert.success('Bỏ quyền thành công')
    }
  }
}
/* eslint-disable no-unused-vars */
