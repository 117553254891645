/* eslint-disable vue/valid-v-slot */

import alert from '@/plugins/alert'
import {Teacher, User, TeacherExam, Role} from '@/plugins/api'
import {v4 as uuidv4} from 'uuid'
/* eslint-disable no-unused-vars */

export default {
  namespaced: true,
  state: {
    count: 0,
    teachers: [],
    teacherSearchParams: {},
    pageText: '',
    loading: false
  },
  actions: {
    async fetchTeachers({commit}, options) {
      const result = await Teacher.fetch({...options, _limit: -1})
      return result
    },
    async searchTeachers({commit}, query) {
      const teacherSearchParams = {
        ...query
      }
      commit('changeState', {teacherSearchParams})
    },
    async createTeacher({commit}, data) {
      try {
        const t = await Teacher.create({...data})
        const role = await Role.queryRoles({type: 'teacher'})
        if (!role) {
          alert.error('Tạo thất bại: role invalid')
        }
        if (t) {
          data.teacher = t._id

          const user = await User.create({
            username: data.username,
            password: data.password,
            ...data,
            email: data.email ? data.email : uuidv4() + '@gmail.com',
            type: 'teacher',
            role: role._id
          })
          alert.success('Tạo Giáo Viên Thành Công!')
          if (!user) {
            alert.error('Tạo thất bại!')
            return
          }
          return t
        } else {
          alert.error('Tạo Thất Bại!')
        }
      } catch (error) {
        console.error(error)
      }
    },
    async fetchTeacherExams({commit}, options) {
      return await TeacherExam.fetch({...options})
    },
    async fetchTeacherExam({commit}, id) {
      return await TeacherExam.fetchOne(id)
    },
    async createTeacherExam({commit}, data) {
      return await TeacherExam.create(data)
    },
    async updateTeacherExam({commit}, {id, ...data}) {
      return await TeacherExam.update(id, {...data})
    },
    async countTeacherExams({commit}, options) {
      return await TeacherExam.count(options)
    },
    async removeTeacherExam({commit}, id) {
      await TeacherExam.remove(id)
      alert.success('Bỏ giáo viên ra khỏi kỳ thi thành công')
    }
  },
  mutations: {
    setPageText(state, pageText) {
      state.pageText = pageText
    },
    setCount(state, count) {
      state.count = count
    }
  }
}
