import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import {createStore} from 'vuex-extensions'
import _ from 'lodash'

import {axiosPlugin} from '@/plugins/axios'
import activeCode from './active-code/activeCode.js'
import auth from './app/auth.js'
import user from './app/user.js'
import role from './app/role.js'
import policies from './policies/policies.js'
import layout from './app/layout.js'
import cv from './cv/cv.js'
import upload from './other/upload.js'
import notification from './notification/notification.js'
import constant from './other/constant.js'

import lead from './lead/lead.js'
import log from './lead/log.js'
import appointment from './lead/appointment.js'
import product from './lead/product.js'
import exam from './exam/exam.js'
import subject from './exam/subject.js'
import room from './exam/room.js'
import student from './exam/student.js'
import mark from './exam/mark.js'
import teacher from './teacher/teacher.js'
import teachers from './teacher/teachers.js'
import monitor from './exam/monitor.js'
import roomSubject from './exam/roomSubject.js'
import subjectGroup from './exam/subjectGroup.js'
import examRoom from './exam/examRoom.js'
import indexing from './exam/indexing.js'
import sms from './notification/sms.js'
import email from './notification/email.js'
Vue.use(Vuex)

export default createStore(Vuex.Store, {
  plugins: [
    createPersistedState({
      key: 'enrollment',
      paths: [
        'auth.user',
        'auth.isAuthenticated',
        'auth.jwt',
        'auth.isConfirmedOTP',
        'auth.role',
        'auth.isRequestingReset'
      ]
    }),
    axiosPlugin
  ],
  modules: {
    auth,
    layout,
    activeCode,
    cv,
    upload,
    user,
    lead,
    log,
    appointment,
    product,
    notification,
    role,
    policies,
    constant,
    exam,
    student,
    room,
    subject,
    mark,
    teacher,
    teachers,
    monitor,
    roomSubject,
    subjectGroup,
    examRoom,
    indexing,
    sms,
    email
  },
  mixins: {
    mutations: {
      changeState: function(state, changed) {
        Object.entries(changed).forEach(([name, value]) => {
          state[name] = value
        })
      },
      changeDeepState: function(state, changed) {
        Object.entries(changed).forEach(([firstChildName, diff]) => {
          const firstChildValue = {...state[firstChildName]}
          Object.entries(diff).forEach(([path, diffValue]) => {
            _.setWith(firstChildValue, path, diffValue)
          })
          state[firstChildName] = firstChildValue
        })
      }
    }
  }
})
