var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "1024px", width: "100%" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "div",
                {
                  class: {
                    "text-subtitle-1 font-weight-bold title--text":
                      _vm.$vuetify.breakpoint.smAndDown,
                    "title--text": _vm.$vuetify.breakpoint.mdAndUp
                  }
                },
                [_vm._v(" Hướng dẫn mua mã hồ sơ ")]
              ),
              _c("v-spacer"),
              _c(
                "v-icon",
                {
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("mdi-close")]
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "div",
            {
              staticClass: "iframe-container",
              style: _vm.getIframeContainerHeight
            },
            [
              _c("iframe", {
                staticClass: "responsive-iframe",
                staticStyle: { border: "none" },
                attrs: { src: _vm.src }
              })
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }