var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        [
          _c("PluginAlert"),
          _c("PluginConfirmDialog"),
          _c("PluginLoading"),
          _c("MainToolbar"),
          _c("router-view"),
          _c("DocumentDialog"),
          _c("SignInDialog"),
          _c("ForgotPasswordDialog"),
          _c("ConfirmForgotPasswordDialog"),
          _c("NewPasswordDialog"),
          _c("SignUpDialog"),
          _c("ConfirmSignupDialog"),
          _vm.isDevelopmentBuild
            ? _c("div", { attrs: { id: "notice" } }, [
                _c("div", { staticClass: "error--text text-subtitle-1" }, [
                  _vm._v("Development Build. v" + _vm._s(_vm.version))
                ])
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }