/* eslint-disable no-unused-vars */
import api, {User} from '@/plugins/api'
import utils from '@/plugins/utils'
import {get, last} from 'lodash'
export default {
  namespaced: true,
  state: {
    adminUsers: [],
    users: []
  },
  actions: {
    async countUsers({commit}, options) {
      const userCount = await User.count({...options})
      const adminCount = await User.count({...options, 'role.type': 'admin'})
      return userCount - adminCount
    },
    async fetchUsers({commit}, options) {
      const foundUsers = await User.fetch({...options, _limit: -1, _sort: 'updatedAt:DESC'})
      commit('setUsers', foundUsers)
      return foundUsers
    },
    async createUser({commit}, data) {
      return await User.create({...data})
    },
    async updateUser({commit}, {id, ...data}) {
      return await User.update(id, {...data})
    },
    async fetchAdminUsers({commit}, options) {
      const admins = await User.fetch({...options, 'role.type': 'admin'})
      commit('setAdminUsers', admins)
      return admins
    },
    async fetchAdminUser({commit}, userId) {
      const user = await User.fetchOne(userId)
      commit('setAdminUser', user)
      return user
    },
    // eslint-disable-next-line
    async generateUserName({dispatch}, name = '') {
      let userNameIndex = utils.generateUserName(utils.clearUnicode(name))
      let userNo = 0
      userNameIndex = utils.removeUnicode(userNameIndex).toLowerCase()
      const users = await api.User.search({
        username_indexing: userNameIndex,
        type_ne: 'student',
        _sort: 'username_no:DESC',
        _limit: 1
      })
      if (users.length) userNo = get(last(users), 'username_no', 0) + 1
      return {
        username: `${userNameIndex}${userNo || ''}`,
        username_indexing: userNameIndex,
        username_no: userNo
      }
    }
  },
  mutations: {
    setAdminUsers(state, adminUsers) {
      state.adminUsers = adminUsers.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [currentValue.id]: currentValue
        }),
        {}
      )
    },
    setUsers(state, users) {
      state.users = users.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [currentValue.id]: currentValue
        }),
        {}
      )
    },
    setAdminUser(state, adminUser) {
      state.adminUsers = {
        [adminUser.id]: adminUser,
        ...state.adminUsers
      }
    }
  },
  getters: {
    users: state => {
      return Object.values(state.users)
    },
    adminUsers: state => {
      return Object.values(state.adminUsers)
    },
    adminUser: state => id => {
      return state.adminUsers[id]
    }
  }
}
/* eslint-enable no-unused-vars */
