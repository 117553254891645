/* eslint-disable no-unused-vars */
import alert from '@/plugins/alert'
import utils from '@/plugins/utils'
import router from '@/router'
import api from '../../plugins/api'
import _ from 'lodash'
import {get, zipObject, fill} from 'lodash'
import moment from 'moment'
export default {
  namespaced: true,
  state: {
    user: null,
    jwt: null,
    role: {},
    isAuthenticated: false,
    isConfirmedOTP: false,
    isRequestingReset: false,
    isConfirmedResetOTP: false,
    userPhone: '',
    username: '',
    confirmFailTime: 0,
    loginFailTime: 0,
    resetFailTime: 0,
    policies: {}
  },
  actions: {
    async signIn({dispatch, commit}, {identifier = '', password = '', type = 'enduser'} = {}) {
      try {
        let func = api.Auth.signIn
        if (type === 'admin') func = api.Auth.signInAdmin
        const {jwt, user, policies} = await func({identifier, password})
        commit('setUser', user)
        commit('setJwt', jwt)
        commit('setRole', get(user, 'role', {}))
        dispatch('setAuthPolicies', policies)
      } catch (error) {
        if (error === 'Identifier or password invalid.') alert.error('Sai thông tin đăng nhập')
        console.error('signIn', error)
      }
    },
    async getUserInfo({commit, state}) {
      if (!state.user || !state.user.id) {
        console.error('No user logged in to fetch info for')
        return
      }

      try {
        const userData = await api.User.fetchOne(state.user.id)
        commit('setUser', userData)
        return userData
      } catch (error) {
        console.error('Error fetching user info:', error)
      }
    },

    signOut({commit}) {
      commit('signout')
    },
    async signUp(
      {commit},
      {username, password, phone, email, name, userStudentData, cfToken, enrollmentGrade, emailData}
    ) {
      try {
        // const rndEmail = `student${Date.now()}@ltv.edu.vn`
        // const userPhone = utils.getValidUserPhone(phone)
        const {user, jwt} = await api.Auth.register(
          username,
          password,
          phone,
          email,
          cfToken,
          enrollmentGrade,
          name,
          userStudentData
        )
        commit('setUser', user)
        commit('setJwt', jwt)
        await api.Auth.sendEmail(emailData)
        await api.Auth.sendText({parentPhone: phone})
        const result = await api.Auth.requestRegisterOTP({phone, username})
        alert.success(result)
        return user
      } catch (error) {
        console.log(error)
        if (error === 'Email already taken' || error === 'Email is already taken.') {
          alert.error('Thông tin đã có trong hệ thống. Xin vui lòng kiểm tra lại!')
          return
        }
        alert.error(error)
      }
    },
    async requestRegisterOTP({commit}, {phone, username}) {
      try {
        const result = await api.Auth.requestRegisterOTP({phone, username})
        alert.success(result)
      } catch (error) {
        console.error('request OTP', error)
      }
    },
    async confirmSignup({commit, state}, {phone, username, confirmOTP}) {
      console.log('in store')
      try {
        const result = await api.Auth.confirmSignupOTP(phone, username, confirmOTP)
        console.log(result)
        alert.success(result)
        commit('setConfirmOTP', true)
      } catch (error) {
        alert.error(error)
      }
    },
    async requestResetOTP({commit}, {userPhone}) {
      try {
        const result = await api.Auth.requestResetOTP(userPhone)
        commit('setUserPhone', userPhone)
        commit('isRequestingReset', true)
        alert.success(result)
      } catch (error) {
        alert.error(error)
      }
    },
    async confirmResetPassword({commit}, {userPhone, otp}) {
      try {
        const result = await api.Auth.confirmResetOTP(userPhone, otp)
        commit('isRequestingReset', false)
        commit('isConfirmedResetOTP', true)
        alert.success(result)
      } catch (error) {
        alert.error(error)
      }
    },
    async checkAuthPolicies({commit}, {roleId}) {
      const id = roleId
      const rolePolicies = await api.Policies.checkAuthPolicies(id)
      const listPolicies = rolePolicies[0].policies
      let policies = {}
      await listPolicies.forEach(element => {
        policies[element.code] = true
      })
      commit('setPolicies', policies)
    },

    async changePassword({commit}, {userPhone, newPassword, confirmNewPassword}) {
      try {
        const result = await api.Auth.changePassword(userPhone, newPassword, confirmNewPassword)
        commit('isConfirmedResetOTP', false)
        alert.success(result)
      } catch (error) {
        alert.error(error)
      }
    },
    async changeUserPassword({commit}, {id, oldPassword, password}) {
      try {
        const result = await api.Auth.changeUserPassword(id, oldPassword, password)
        alert.success(result)
        return result
      } catch (error) {
        alert.error(error)
      }
    },
    setAuthPolicies({commit}, policies) {
      const policiesObj = zipObject(policies, fill(Array(policies.length), true))
      commit('setPolicies', policiesObj)
    },
    setRole({commit}, role) {
      commit('setRole', role)
    },
    setConfirmFailTime({commit}, data) {
      commit('setConfirmFailTime', data)
    },
    setLoginFailTime({commit}, data) {
      commit('setLoginFailTime', data)
    },
    setResetFailTime({commit}, data) {
      commit('setResetFailTime', data)
    },
    setUserPhone({commit}, data) {
      commit('setUserPhone', data)
    },
    isRequestingReset({commit}, data) {
      commit('isRequestingReset', data)
    },
    isConfirmedResetOTP({commit}, data) {
      commit('isConfirmedResetOTP', data)
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user
      if (user) {
        state.role = user.role
        state.isAuthenticated = true
        state.isConfirmedOTP = user.isConfirmedOTP
        state.userPhone = user.parentPhone
      } else {
        state.isAuthenticated = false
        state.role = null
        state.userPhone = ''
        state.isConfirmedOTP = false
      }
    },
    setJwt(state, jwt) {
      state.jwt = jwt
    },
    setPolicies(state, policies) {
      state.policies = {...policies}
    },
    setRole(state, role) {
      state.role = role
    },
    signout(state, role) {
      state.user = null
      state.jwt = null
      state.role = {}
      state.isAuthenticated = false
      state.isConfirmedOTP = false
      state.isRequestingReset = false
      state.isConfirmedResetOTP = false
      state.userPhone = ''
      state.confirmFailTime = 0
      state.loginFailTime = 0
      state.resetFailTime = 0
    },
    setUserPhone(state, data) {
      state.userPhone = data
    },
    setConfirmOTP(state, data) {
      state.isConfirmedOTP = data
    },
    setConfirmFailTime(state, data) {
      state.confirmFailTime = data
    },
    setLoginFailTime(state, data) {
      state.loginFailTime = data
    },
    setResetFailTime(state, data) {
      state.resetFailTime = data
    },
    isRequestingReset(state, data) {
      state.isRequestingReset = data
    },
    isConfirmedResetOTP(state, data) {
      state.isConfirmedResetOTP = data
    }
  },
  getters: {
    jwt(state) {
      return state.jwt
    },
    user(state) {
      return !state.user ? {} : state.user
    },
    role(state) {
      return state.role || state.user.role || {}
    },
    userPhone(state) {
      return state.userPhone
    },
    isAuthenticated(state) {
      return state.isAuthenticated
    },
    isConfirmedOTP(state) {
      return state.isConfirmedOTP
    },
    confirmFailTime(state) {
      return state.confirmFailTime
    },
    loginFailTime(state) {
      return state.loginFailTime
    },
    resetFailTime(state) {
      return state.resetFailTime
    },
    isRequestingReset(state) {
      return state.isRequestingReset
    },
    isConfirmedResetOTP(state) {
      return state.isConfirmedResetOTP
    }
  }
}
/* eslint-disable no-unused-vars */
