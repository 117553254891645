export default {
  namespaced: true,
  state: {
    roleConfigs: [
      {title: 'Sale', value: 'sale'},
      {title: 'Tuyển sinh', value: 'enroll'},
      {title: 'Tài chính', value: 'finance'},
      {title: 'Đào tạo & Khảo thi', value: 'exam'},
      {title: 'Cài đặt nâng cao', value: 'setting'}
    ]
  },
  getters: {
    roleConfigs: state => state.roleConfigs
  }
}
