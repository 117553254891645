import {Room} from '@/plugins/api'
import alert from '@/plugins/alert'
/* eslint-disable no-unused-vars */
export default {
  namespaced: true,
  actions: {
    async fetchRooms({commit}, options) {
      const rooms = await Room.fetch({...options})
      return rooms
    },
    async fetchRoom({commit}, id) {
      const room = await Room.fetchOne(id)
      return room
    },
    async createRoom({commit}, data) {
      const newRoom = await Room.create(data)
      return newRoom
    },
    async updateRoom({commit}, {id, ...roomData}) {
      const updateRoom = await Room.update(id, {...roomData})
      return updateRoom
    },
    async countRooms({commit}, options) {
      try {
        return await Room.count(options)
      } catch (e) {
        alert.error(e)
      }
    }
  }
}
/* eslint-disable no-unused-vars */
