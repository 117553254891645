var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      staticClass: "plugin__alert",
      attrs: {
        value: _vm.alert.show,
        color: _vm.color,
        timeout: _vm.timeout,
        top: ""
      },
      on: { input: _vm.close }
    },
    [
      _c("v-icon", { staticClass: "mr-3 mt-n1" }, [_vm._v(_vm._s(_vm.icon))]),
      _c("span", {
        staticClass: "text-h6",
        domProps: { innerHTML: _vm._s(_vm.alert.message) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }