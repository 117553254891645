var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("navigation-drawer", {
        staticClass: "d-print-none",
        attrs: { drawer: _vm.drawer }
      }),
      _c("plugin-confirm-dialog", { staticClass: "d-print-none" }),
      !_vm.simpleLayout
        ? _c("main-toolbar", {
            staticClass: "d-print-none",
            on: {
              "toggle-drawer": function($event) {
                return _vm.toggleDrawer($event)
              }
            }
          })
        : _vm._e(),
      _c(
        "v-main",
        { staticClass: "d-print-none" },
        [
          _c("plugin-alert"),
          _c("plugin-loading"),
          _c("confirm", { ref: "confirm" }),
          _c(
            "v-container",
            {
              staticClass: "overflow-auto pa-0",
              staticStyle: {
                "overflow-x": "hidden",
                "background-color": "#fafafa",
                "align-items": "start"
              },
              attrs: {
                id: "container",
                fluid: "",
                "fill-height": "",
                "grid-list-xl": ""
              }
            },
            [
              _c("router-view", {
                staticStyle: {
                  "min-width": "1024px",
                  "max-width": "1400px",
                  margin: "0px auto"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }