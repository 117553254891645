var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.active
    ? _c(
        "v-layout",
        { attrs: { row: "", "justify-center": "" } },
        [
          _c(
            "v-dialog",
            {
              attrs: { value: _vm.active, persistent: "", "max-width": "290" }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v(_vm._s(_vm.title))]),
                  _vm.message
                    ? _c("v-card-text", [_vm._v(_vm._s(_vm.message))])
                    : _vm._e(),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "green", text: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.confirm.apply(null, arguments)
                            }
                          }
                        },
                        [_vm._v("OK")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "red", text: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.cancel.apply(null, arguments)
                            }
                          }
                        },
                        [_vm._v("Hủy")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }