import {Email, InfoProgress} from '../../plugins/api'
/* eslint-disable no-unused-vars */

export default {
  namespaced: true,
  actions: {
    async fetchEmails({commit}, options) {
      const emails = await Email.fetch({...options})
      return emails
    },
    async countEmails({commit}, options) {
      const count = await Email.count({...options})
      return count
    },
    async fetchEmailProgress({commit}, options) {
      const progress = await InfoProgress.fetch({...options, type: 'email'})
      return progress[0]
    },
    async updateEmailProgress({commit}, {id, ...data}) {
      const updateProg = await InfoProgress.update(id, {...data})
      return updateProg
    }
  }
}
/* eslint-disable no-unused-vars */
