/* eslint-disable no-unused-vars */
import {Log} from '@/plugins/api'
export default {
  namespaced: true,
  state: {
    Leads: [],
    count: 0
  },
  actions: {
    async fetchLogs({commit}, options) {
      const logs = await Log.fetch({...options})
      return logs
    }
  }
}
/* eslint-enable no-unused-vars */
