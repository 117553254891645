var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "480px" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("div", { staticStyle: { color: "#797979" } }, [
                _vm._v("Quên mật khẩu")
              ]),
              _c("v-spacer"),
              _c(
                "v-icon",
                {
                  staticClass: "mr-n1",
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("mdi-close")]
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "py-8" },
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c("div", { staticClass: "text-subtitle-1" }, [
                    _vm._v(" Số điện thoại "),
                    _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")])
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      placeholder: "Nhập số điện thoại tại đây",
                      name: "login",
                      rules: _vm.phoneRules,
                      type: "text",
                      color: "primary",
                      outlined: "",
                      "validate-on-blur": ""
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.submit.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.identifier,
                      callback: function($$v) {
                        _vm.identifier = $$v
                      },
                      expression: "identifier"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "white--text text-subtitle-1 btn-text text-none mt-6",
                  staticStyle: { width: "100%" },
                  attrs: {
                    depressed: "",
                    "x-large": "",
                    block: "",
                    color: "primary",
                    loading: _vm.loading
                  },
                  on: {
                    click: function($event) {
                      return _vm.submit()
                    }
                  }
                },
                [_vm._v("Tiếp theo ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }