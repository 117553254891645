var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-data-table",
    _vm._b(
      {
        staticClass: "document-table",
        attrs: {
          "item-key": "id",
          headers: _vm.headers,
          loading: _vm.loading,
          items: _vm.CVs,
          "items-per-page": 10,
          "disable-sort": _vm.$vuetify.breakpoint.smAndDown,
          "footer-props": { "items-per-page-text": "Số Hồ Sơ một trang" }
        },
        scopedSlots: _vm._u(
          [
            {
              key: `footer.page-text`,
              fn: function(items) {
                return [
                  _vm._v(
                    " Hồ sơ thứ " +
                      _vm._s(items.pageStart) +
                      " - " +
                      _vm._s(items.pageStop) +
                      " trên tổng " +
                      _vm._s(items.itemsLength) +
                      " Hồ sơ "
                  )
                ]
              }
            },
            {
              key: `item.code`,
              fn: function({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "document-btn text-none text-decoration-underline px-0",
                      attrs: { color: "primary", plain: "" },
                      on: {
                        click: function($event) {
                          return _vm.onDocumentClick(item.id)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm._f("getCode")(item)) + " ")]
                  )
                ]
              }
            },
            {
              key: `item.type`,
              fn: function({ item }) {
                return [
                  _c(
                    "v-chip",
                    {
                      staticClass: "d-flex justify-center",
                      staticStyle: { width: "65px" },
                      attrs: { color: _vm.getGradeColor(item.type), small: "" }
                    },
                    [_vm._v(" " + _vm._s(_vm._f("getGrade")(item)) + " ")]
                  )
                ]
              }
            },
            {
              key: `item.department`,
              fn: function({ item }) {
                return [
                  _vm._v(" " + _vm._s(_vm._f("getDepartment")(item)) + " ")
                ]
              }
            },
            {
              key: `item.student`,
              fn: function({ item }) {
                return [
                  _vm._v(" " + _vm._s(_vm._f("getStudentName")(item)) + " ")
                ]
              }
            },
            {
              key: `item.status`,
              fn: function({ item }) {
                return [
                  _c(
                    "v-chip",
                    {
                      staticClass: "d-flex justify-center",
                      attrs: { color: _vm.getStatusColor(item), small: "" }
                    },
                    [_vm._v(" " + _vm._s(_vm._f("getStatus")(item)) + " ")]
                  )
                ]
              }
            },
            {
              key: `item.action`,
              fn: function({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass: "document-btn text-none px-0",
                      attrs: { color: "primary", plain: "" },
                      on: {
                        click: function($event) {
                          return _vm.onDocumentClick(item.id)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm._f("getActionName")(item)))]
                  )
                ]
              }
            },
            {
              key: "no-data",
              fn: function() {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column align-center justify-center pa-6"
                    },
                    [
                      _c("v-img", {
                        attrs: {
                          width: "70",
                          src: require("@/assets/homepage/no-data.svg")
                        }
                      }),
                      _c("div", { staticClass: "mt-4" }, [
                        _vm._v("Nhập mã hồ sơ để bắt đầu tạo hồ sơ")
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      },
      "v-data-table",
      this.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }