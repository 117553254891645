import { render, staticRenderFns } from "./User.vue?vue&type=template&id=8a1b0276&scoped=true"
import script from "./User.vue?vue&type=script&lang=js"
export * from "./User.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a1b0276",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8a1b0276')) {
      api.createRecord('8a1b0276', component.options)
    } else {
      api.reload('8a1b0276', component.options)
    }
    module.hot.accept("./User.vue?vue&type=template&id=8a1b0276&scoped=true", function () {
      api.rerender('8a1b0276', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/admin/User.vue"
export default component.exports