<template>
  <v-app-bar color="white" class="elevation-2" max-height="80px" :height="getBarHeight" app dark>
    <div
      class="d-flex justify-start align-center"
      :class="{
        'px-4 md-height': $vuetify.breakpoint.mdAndUp
      }"
    >
      <v-img
        alt="Vuetify Logo"
        class="mr-2"
        contain
        height="100%"
        src="@/assets/homepage/logo-tqb.svg"
        transition="scale-transition"
      />
    </div>
    <v-spacer></v-spacer>
    <div class="display-guest-toolbar">
      <GuestToolbar :isOpen="true" v-if="isGuestBar" />
      <UserToolbar :isOpen="true" v-if="!isGuestBar" />
    </div>
  </v-app-bar>
</template>

<script>
import GuestToolbar from '@/components/layout/GuestToolbar.vue'
import UserToolbar from '@/components/layout/UserToolbar.vue'
import {mapGetters, mapActions} from 'vuex'

export default {
  components: {
    GuestToolbar,
    UserToolbar
  },
  data() {
    return {
      ENV: ''
    }
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated', 'user', 'isConfirmedOTP']),
    ...mapGetters('cv', ['systemTime']),

    getBarHeight() {
      if (this.$vuetify.breakpoint.mdAndUp) return '80px'
      else return '68px'
    },
    isGuestBar() {
      if (this.isAuthenticated && this.user) return false
      return true
    }
  },
  // async created() {
  //   await this.checkSystemTime()
  //   this.ENV = process.env.NODE_ENV
  // },
  methods: {
    ...mapActions('cv', ['checkSystemTime'])
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');
#school-title {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 21px;
}
.desktop-title {
  font-size: 21px;
}
.mobile-title {
  font-size: 18px;
}
</style>

<style lang="scss" scoped>
.display-guest-toolbar {
  display: block;
  @media screen and (max-width: 600px) {
    display: block;
  }
  @media screen and (max-width: 440px) {
    display: none;
  }
}
</style>
