import {SubjectGroup} from '@/plugins/api'
import alert from '@/plugins/alert'

/* eslint-disable no-unused-vars */
export default {
  namespaced: true,
  actions: {
    async fetchSubjectGroups({commit}, options) {
      const subGroups = await SubjectGroup.fetch({...options})
      return subGroups
    },
    async fetchSubjectGroup({commit}, id) {
      const subGroup = await SubjectGroup.fetchOne(id)
      return subGroup
    },
    async createSubjectGroup({commit}, data) {
      return await SubjectGroup.create(data)
    },
    async updateSubjectGroup({commit}, {id, ...data}) {
      const updatedGroup = await SubjectGroup.update(id, {...data})
      return updatedGroup
    },
    async countSubjectGroups({commit}, options) {
      try {
        return await SubjectGroup.count(options)
      } catch (e) {
        alert.error(e)
      }
    }
  }
}
/* eslint-disable no-unused-vars */
