var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.loading.active,
          expression: "loading.active"
        }
      ],
      staticClass: "plugin__loading"
    },
    [_c("v-progress-circular", { attrs: { indeterminate: "" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }