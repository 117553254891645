var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      staticClass: "elevation-2 white",
      class: { blue: _vm.isXsScreen, "darken-4": _vm.isXsScreen },
      attrs: { app: "", dense: "", height: "64px" }
    },
    [
      _c("v-app-bar-nav-icon", {
        staticClass: "d-flex d-sm-none",
        attrs: { dark: "" },
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.toggleDrawer.apply(null, arguments)
          }
        }
      }),
      _c(
        "v-toolbar-title",
        { staticClass: "pl-0" },
        [
          _c("v-img", {
            staticClass: "d-flex d-sm-none",
            attrs: {
              src: require("../../assets/default-logo.png"),
              height: "32px",
              width: "132px",
              contain: ""
            }
          })
        ],
        1
      ),
      _c("v-spacer"),
      _c("v-toolbar-items", [_c("User")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }