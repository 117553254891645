var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-center" },
    [
      _c(
        "v-menu",
        {
          attrs: { "min-width": "120px", "max-width": "200px", "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function({ on, attrs }) {
                return [
                  _c(
                    "div",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "info--text",
                          class: {
                            "mr-4 btn-text": _vm.$vuetify.breakpoint.mdAndUp
                          }
                        },
                        "div",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(" Xin chào quý phụ huynh! ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.showMenu,
            callback: function($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu"
          }
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-title",
                    {
                      on: {
                        click: function($event) {
                          return _vm.onSignOut()
                        }
                      }
                    },
                    [_vm._v("Đăng xuất")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.isOpen
        ? _c(
            "v-btn",
            {
              staticClass: "text-none ml-6",
              class: { "mr-4 btn-text": _vm.$vuetify.breakpoint.mdAndUp },
              attrs: { color: "error", outlined: "", rounded: "" },
              on: {
                click: function($event) {
                  return _vm.onSignOut()
                }
              }
            },
            [_vm._v("Đăng xuất")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }