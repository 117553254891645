/* eslint-disable no-unused-vars */
import {Product, ProductType} from '@/plugins/api'
export default {
  namespaced: true,
  state: {
    Leads: [],
    count: 0
  },
  actions: {
    async fetchProducts({commit}, options) {
      const products = await Product.fetch({...options, _sort: 'grade:desc,productType.index:asc'})
      return products
    },
    async fetchProduct({commit}, productId) {
      const product = await Product.fetchOne(productId)
      return product
    },
    async createProduct({commit}, data) {
      const newProd = await Product.create(data)
      return newProd
    },
    async updateProduct({commit}, {id, ...data}) {
      const updatedProd = await Product.update(id, {...data})
      return updatedProd
    },
    async fetchProductTypes({commit}, options) {
      const types = await ProductType.fetch({...options, _sort: 'index:ASC'})
      return types
    }
  }
}
/* eslint-enable no-unused-vars */
