import { render, staticRenderFns } from "./DocumentTutorial.vue?vue&type=template&id=639bc103&scoped=true"
import script from "./DocumentTutorial.vue?vue&type=script&lang=js"
export * from "./DocumentTutorial.vue?vue&type=script&lang=js"
import style0 from "./DocumentTutorial.vue?vue&type=style&index=0&id=639bc103&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "639bc103",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('639bc103')) {
      api.createRecord('639bc103', component.options)
    } else {
      api.reload('639bc103', component.options)
    }
    module.hot.accept("./DocumentTutorial.vue?vue&type=template&id=639bc103&scoped=true", function () {
      api.rerender('639bc103', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/document/DocumentTutorial.vue"
export default component.exports