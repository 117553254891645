<template>
  <router-view></router-view>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import {get} from 'lodash'
export default {
  async mounted() {
    if (this.role && this.role !== {} && this.role.type !== 'authenticated') {
      await this.resetPolicies()
    }
  },
  computed: {
    ...mapState('auth', ['role', 'user', 'policies'])
  },
  methods: {
    ...mapActions('auth', ['checkAuthPolicies']),
    async resetPolicies() {
      if (this.role && this.role !== {} && this.role.type !== 'authenticated') {
        const roleId = get(this.user, 'role.id', '')
        if (typeof roleId === 'string' && roleId.length) await this.checkAuthPolicies({roleId: this.user.role.id})
      }
    }
  }
}
</script>
<style>
.container.fill-height > .row {
  flex: 1 1 100%;
  max-width: calc(100%) !important;
}
.cursor-pointer {
  cursor: pointer;
}
.v-card--link:focus:before {
  opacity: 0 !important;
}
.v-toolbar__content {
  z-index: 10002 !important;
}
.required label::after {
  content: ' *';
  color: red;
}
</style>
