/* eslint-disable no-unused-vars */
import alert from '@/plugins/alert'
import {Lead, CountStatus, CountSucceed, LeadProgress} from '@/plugins/api'
export default {
  namespaced: true,
  state: {
    Leads: [],
    count: 0
  },
  actions: {
    async fetchLeads({commit}, options) {
      try {
        const leads = await Lead.fetch({...options, _sort: 'createdAt:DESC'})
        commit('setLeads', leads)
        return leads
      } catch (e) {
        alert.error(e)
      }
    },
    async fetchLead({commit}, leadId) {
      const lead = await Lead.fetchOne(leadId)
      return lead
    },
    async countLeads({commit}, options) {
      try {
        return await Lead.count(options)
      } catch (e) {
        alert.error(e)
      }
    },
    async createLead({commit}, data) {
      const newLead = await Lead.create(data)
      return newLead
    },
    async updateLead({commit, state}, {id, ...leadData}) {
      const updatedLead = await Lead.update(id, {...leadData})
      return updatedLead
    },
    async countStatus({commit}, options) {
      const count = await CountStatus.fetch({...options})
      return count
    },
    async countSucceed({commit}, options) {
      const count = await CountSucceed.fetch({...options})
      return count
    },
    async fetchLeadProgress({commit}, options) {
      const progress = await LeadProgress.fetch({...options})
      return progress[0]
    },
    async updateLeadProgress({commit}, {id, ...data}) {
      const updatedProg = await LeadProgress.update(id, {...data})
      return updatedProg
    }
  },
  mutations: {
    setLeads(state, Leads) {
      state.Leads = Leads.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [`${currentValue.id}`]: currentValue
        }),
        {}
      )
    }
  },
  getters: {
    Leads: state => {
      return Object.values(state.Leads)
    }
  }
}
/* eslint-enable no-unused-vars */
