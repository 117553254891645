var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "640px", persistent: "", scrollable: "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("div", { staticClass: "title--text" }, [_vm._v("Đăng ký")]),
              _c("v-spacer"),
              _c(
                "v-icon",
                {
                  staticClass: "mr-n1",
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("mdi-close")]
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "pt-4 pb-2" },
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c("div", { staticClass: "text-subtitle-1" }, [
                    _vm._v("Đăng ký tuyển sinh Khối 6"),
                    _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")])
                  ]),
                  _c(
                    "v-radio-group",
                    {
                      staticClass: "ma-0",
                      attrs: { column: "", rules: [_vm.$rules.required] },
                      model: {
                        value: _vm.enrollmentGrade,
                        callback: function($$v) {
                          _vm.enrollmentGrade = $$v
                        },
                        expression: "enrollmentGrade"
                      }
                    },
                    [
                      _c("v-radio", {
                        attrs: { label: "Khối 6", value: "secondary" }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "text-subtitle-1" }, [
                    _vm._v("Họ tên học sinh "),
                    _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")])
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      placeholder: "VD: Nguyễn Văn A",
                      name: "login",
                      color: "primary",
                      rules: [_vm.$rules.required],
                      outlined: ""
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.submit.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.studentName,
                      callback: function($$v) {
                        _vm.studentName = $$v
                      },
                      expression: "studentName"
                    }
                  }),
                  _c("div", { staticClass: "text-subtitle-1" }, [
                    _vm._v("Ngày tháng năm sinh "),
                    _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")])
                  ]),
                  _c(
                    "v-menu",
                    {
                      ref: "menu",
                      attrs: {
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        transition: "scale-transition",
                        "offset-y": "",
                        "min-width": "auto"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function({ on, attrs }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        value: _vm.formattedDOB,
                                        placeholder: "Chọn ngày sinh",
                                        readonly: "",
                                        rules: [_vm.$rules.required],
                                        outlined: ""
                                      }
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.menu,
                        callback: function($$v) {
                          _vm.menu = $$v
                        },
                        expression: "menu"
                      }
                    },
                    [
                      _c("v-date-picker", {
                        on: {
                          input: function($event) {
                            _vm.menu = false
                          }
                        },
                        model: {
                          value: _vm.dob,
                          callback: function($$v) {
                            _vm.dob = $$v
                          },
                          expression: "dob"
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "text-subtitle-1" }, [
                    _vm._v(
                      " Mã học sinh (của Bộ GD&ĐT, trên phần mềm CSDL ngành)"
                    ),
                    _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")])
                  ]),
                  _c("div", [
                    _c("span", [
                      _vm._v(" Quý PH vui lòng xem cách lấy mã học sinh")
                    ]),
                    _c(
                      "a",
                      {
                        staticStyle: { color: "#1976D2" },
                        attrs: {
                          href:
                            "https://d27ehxdk832fc9.cloudfront.net/HƯỚNG DẪN PHHS CÁCH XEM MÃ ĐỊNH DANH HỌC SINH DO BỘ GIÁO DỤC CẤP DỤC VÀ ĐÀO TẠP CẤP.pdf",
                          target: "_blank"
                        }
                      },
                      [_vm._v(" Tại đây ")]
                    )
                  ]),
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: "##########",
                        expression: "'##########'"
                      }
                    ],
                    attrs: {
                      placeholder: "Nhập mã học sinh tại đây",
                      name: "login",
                      color: "primary",
                      rules: [_vm.$rules.required, _vm.$rules.minLength(10)],
                      outlined: ""
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.submit.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.studentCode,
                      callback: function($$v) {
                        _vm.studentCode = $$v
                      },
                      expression: "studentCode"
                    }
                  }),
                  _c("div", { staticClass: "text-subtitle-1" }, [
                    _vm._v("Trường con đang theo học "),
                    _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")])
                  ]),
                  _c("v-combobox", {
                    attrs: {
                      items: _vm.schoolOptions,
                      "item-text": "name",
                      "item-value": "name",
                      "menu-props": { maxHeight: 120 },
                      outlined: "",
                      clearable: "",
                      "return-object": false,
                      rules: [_vm.$rules.required],
                      autocomplete: "on",
                      placeholder: "Bắt đầu gõ để tìm kiếm..."
                    },
                    model: {
                      value: _vm.studentSchool,
                      callback: function($$v) {
                        _vm.studentSchool = $$v
                      },
                      expression: "studentSchool"
                    }
                  }),
                  _c("div", { staticClass: "text-subtitle-1" }, [
                    _vm._v(
                      " Nguyện vọng trường cấp " +
                        _vm._s(
                          _vm.enrollmentGrade === "secondary" ? "2" : "3"
                        ) +
                        " "
                    ),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("*")
                    ]),
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#707070", "font-size": "12px" }
                      },
                      [_vm._v(" (Dự kiến)")]
                    )
                  ]),
                  _c("v-combobox", {
                    attrs: {
                      items: _vm.expectOptions,
                      "item-text": "name",
                      "item-value": "name",
                      outlined: "",
                      "menu-props": { maxHeight: 120 },
                      clearable: "",
                      autocomplete: "on",
                      "return-object": false,
                      placeholder: "Bắt đầu gõ để tìm kiếm...",
                      rules: [_vm.$rules.required]
                    },
                    model: {
                      value: _vm.expectSchool,
                      callback: function($$v) {
                        _vm.expectSchool = $$v
                      },
                      expression: "expectSchool"
                    }
                  }),
                  _c("div", { staticClass: "text-subtitle-1" }, [
                    _vm._v("Họ tên phụ huynh "),
                    _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")])
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      placeholder: "Nhập tên phụ huynh tại đây",
                      name: "login",
                      color: "primary",
                      rules: [_vm.$rules.required],
                      outlined: ""
                    },
                    model: {
                      value: _vm.parentName,
                      callback: function($$v) {
                        _vm.parentName = $$v
                      },
                      expression: "parentName"
                    }
                  }),
                  _c("div", { staticClass: "text-subtitle-1" }, [
                    _vm._v("Số điện thoại PH "),
                    _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")])
                  ]),
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: "##########",
                        expression: "'##########'"
                      }
                    ],
                    attrs: {
                      placeholder:
                        "Nhập số điện thoại tại đây (Ví dụ: 097372xxxx)",
                      name: "login",
                      type: "tel",
                      color: "primary",
                      rules: _vm.phoneRules,
                      outlined: "",
                      "validate-on-blur": ""
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.submit.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.phone,
                      callback: function($$v) {
                        _vm.phone = $$v
                      },
                      expression: "phone"
                    }
                  }),
                  _c("div", { staticClass: "text-subtitle-1" }, [
                    _vm._v("Email liên hệ "),
                    _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")])
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      placeholder: "VD: abcxyz@gmail.com",
                      name: "login",
                      color: "primary",
                      rules: [_vm.$rules.required, _vm.$rules.email],
                      outlined: ""
                    },
                    model: {
                      value: _vm.parentEmail,
                      callback: function($$v) {
                        _vm.parentEmail = $$v
                      },
                      expression: "parentEmail"
                    }
                  }),
                  _c("v-divider"),
                  _c(
                    "div",
                    {
                      staticClass: "text-subtitle-1",
                      staticStyle: { "margin-top": "22px" }
                    },
                    [
                      _vm._v("Mật khẩu "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*")
                      ])
                    ]
                  ),
                  _c("v-text-field", {
                    attrs: {
                      placeholder: "Nhập mật khẩu tại đây",
                      color: "primary",
                      "append-icon": _vm.showPassword
                        ? "mdi-eye"
                        : "mdi-eye-off",
                      type: _vm.showPassword ? "text" : "password",
                      rules: _vm.passwordRules,
                      outlined: "",
                      "validate-on-blur": ""
                    },
                    on: {
                      "click:append": function($event) {
                        _vm.showPassword = !_vm.showPassword
                      },
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.submit.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  }),
                  _c("div", { staticClass: "text-subtitle-1" }, [
                    _vm._v("Mật khẩu (nhắc lại) "),
                    _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")])
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      placeholder: "Nhập mật khẩu tại đây",
                      "append-icon": _vm.showConfirmPassword
                        ? "mdi-eye"
                        : "mdi-eye-off",
                      type: _vm.showConfirmPassword ? "text" : "password",
                      rules: _vm.confirmPasswordRules,
                      color: "primary",
                      outlined: "",
                      "validate-on-blur": ""
                    },
                    on: {
                      "click:append": function($event) {
                        _vm.showConfirmPassword = !_vm.showConfirmPassword
                      },
                      keyup: [
                        function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.submit.apply(null, arguments)
                        },
                        function($event) {
                          return _vm.$refs.form.validate()
                        }
                      ]
                    },
                    model: {
                      value: _vm.confirmPassword,
                      callback: function($$v) {
                        _vm.confirmPassword = $$v
                      },
                      expression: "confirmPassword"
                    }
                  })
                ],
                1
              ),
              _c("div", {
                staticClass: "cf-turnstile",
                attrs: { "data-sitekey": "0x4AAAAAAAQ-PRt09feV7Bnr" }
              })
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticStyle: { "flex-direction": "column" } },
            [
              _c(
                "div",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text text-subtitle-1 mt-4 text-none",
                      staticStyle: { width: "100%" },
                      attrs: {
                        depressed: "",
                        "x-large": "",
                        color: "primary",
                        loading: _vm.loading,
                        disabled: !_vm.token
                      },
                      on: { click: _vm.submit }
                    },
                    [_vm._v("Đăng ký")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "text-subtitle-1 font-weight-bold mt-2 text-none",
                      staticStyle: { width: "100%" },
                      attrs: { plain: "", "x-large": "", color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.signin()
                        }
                      }
                    },
                    [_vm._v("Đã có tài khoản? Đăng nhập")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }