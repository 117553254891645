import Vue from 'vue'
import VueRouter from 'vue-router'

/* User Page */
import UserLayout from '@/views/homepage/UserLayout.vue'

/* Admin Page */
import MainLayout from '@/views/admin/MainLayout.vue'

Vue.use(VueRouter)

function lazyLoad(view) {
  return () => import(`@/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: UserLayout,
    meta: {
      title: 'Trang chủ'
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: lazyLoad('views/homepage/Home'),
        meta: {
          title: 'Hồ sơ'
        }
      },
      {
        path: '/document',
        name: 'DocumentDetail',
        component: lazyLoad('views/document/DocumentDetail'),
        meta: {
          title: 'Hồ sơ'
        }
      },
      {
        path: '/privacypolicy',
        name: 'Privacy Policies',
        component: lazyLoad('views/homepage/PrivacyPolicy'),
        meta: {
          title: 'Hồ sơ'
        }
      }
    ]
  },
  {
    path: '/admin/signin',
    component: lazyLoad('views/admin/AdminSigninDialog'),
    meta: {
      title: 'Đăng nhập'
    }
  },
  {
    path: '/admin',
    component: MainLayout,
    meta: {
      title: 'Trang chủ'
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: lazyLoad('views/admin/Dashboard'),
        meta: {
          title: 'Trang chủ',
          auth: true
        }
      },
      {
        path: 'accountdetail',
        name: 'Account Detail',
        component: lazyLoad('views/admin/AccountDetail'),
        meta: {
          title: 'Thông tin tài khoản',
          auth: true
        }
      },
      {
        path: 'user',
        name: 'User Management',
        component: lazyLoad('views/admin/UserManagement'),
        meta: {
          title: 'Quản lý tài khoản',
          auth: true
        }
      },
      {
        path: 'active-code',
        name: 'Active Code',
        component: lazyLoad('modules/active-code/ActiveCode'),
        meta: {
          title: 'Quản lý mã hồ sơ',
          auth: true
        }
      },
      {
        path: 'document',
        name: 'Document',
        component: lazyLoad('modules/cv/admin/Document'),
        meta: {
          title: 'Quản lý hồ sơ',
          auth: true
        }
      },
      {
        path: 'document-cancel',
        name: 'DocumentCancel',
        component: lazyLoad('modules/cv/admin/DocumentCancel'),
        meta: {
          title: 'Hồ sơ đã hủy',
          auth: true
        }
      },
      {
        path: 'submit-document',
        name: 'DocumentSubmit',
        component: lazyLoad('modules/cv/admin/DocumentSubmit'),
        meta: {
          title: 'Nộp hồ sơ gốc',
          auth: true
        }
      },
      {
        path: 'grade6-enroll-forms',
        name: 'Grade6EnrollFormPage',
        component: lazyLoad('modules/enroll-form/Grade6EnrollFormPage'),
        meta: {
          title: 'Đơn đăng ký khối 6',
          auth: true
        }
      },
      {
        path: 'grade10-enroll-forms',
        name: 'Grade10EnrollFormPage',
        component: lazyLoad('modules/enroll-form/Grade10EnrollFormPage'),
        meta: {
          title: 'Đơn đăng ký khối 10',
          auth: true
        }
      },
      {
        path: 'grade6-result',
        name: 'Grade6ExamResult',
        component: lazyLoad('modules/cv/admin/Grade6ExamResult'),
        meta: {
          title: 'Kết quả thi Khối 6',
          auth: true
        }
      },
      {
        path: 'grade10-result',
        name: 'Grade10ExamResult',
        component: lazyLoad('modules/cv/admin/Grade10ExamResult'),
        meta: {
          title: 'Kết quả thi Khối 10',
          auth: true
        }
      },
      {
        path: 'notification',
        name: 'Notification',
        component: lazyLoad('modules/notification/index'),
        meta: {
          title: 'Gửi thông báo',
          auth: true
        }
      },
      {
        path: 'lead',
        name: 'Lead',
        component: lazyLoad('modules/lead/LeadPage'),
        meta: {
          title: 'Lead',
          auth: true
        }
      },
      {
        path: 'lead/:id',
        name: 'Lead details',
        component: lazyLoad('modules/lead/leadDetails/LeadDetails'),
        meta: {
          title: 'Thông tin Lead',
          auth: true
        }
      },
      {
        path: 'sale',
        name: 'Sale users',
        component: lazyLoad('modules/user/sale/UserPage'),
        meta: {
          title: 'Nhân viên sale',
          auth: true
        }
      },
      {
        path: 'sale/:id',
        name: 'Sale details',
        component: lazyLoad('modules/user/sale/SaleDetails'),
        meta: {
          title: 'Nhân viên sale',
          auth: true
        }
      },
      {
        path: 'product',
        name: 'Product',
        component: lazyLoad('modules/product/product/ProductPage'),
        meta: {
          title: 'Hệ đào tạo',
          auth: true
        }
      },
      {
        path: 'product/:id',
        name: 'Product details',
        component: lazyLoad('modules/product/product/ProductDetails'),
        meta: {
          title: 'Hệ đào tạo',
          auth: true
        }
      },
      {
        path: 'report',
        name: 'Lead report',
        component: lazyLoad('modules/report/LeadReport'),
        meta: {
          title: 'Báo cáo CRM',
          auth: true
        }
      },
      {
        path: 'sms',
        name: 'Send SMS',
        component: lazyLoad('modules/sms/SmsPage'),
        meta: {
          title: 'Tin nhắn SMS',
          auth: true
        }
      },
      {
        path: 'email',
        name: 'Send Email',
        component: lazyLoad('modules/email/EmailPage'),
        meta: {
          title: 'Email',
          auth: true
        }
      },
      {
        path: 'policies',
        name: 'Role policies',
        component: lazyLoad('modules/policy/PolicyConfig'),
        meta: {
          title: 'Phân quyền',
          auth: true
        }
      },
      {
        path: 'exams',
        name: 'Exams',
        component: lazyLoad('modules/exam/ExamPage'),
        meta: {
          title: 'Kỳ thi',
          auth: true
        }
      },
      {
        path: 'exams/:id',
        name: 'Exam details',
        component: lazyLoad('modules/exam/ExamDetailsPage'),
        meta: {
          title: 'Kỳ thi',
          auth: true
        }
      },
      {
        path: 'students',
        name: 'Students list',
        component: lazyLoad('modules/student/StudentPage'),
        meta: {
          title: 'Học sinh',
          auth: true
        }
      },
      {
        path: 'markInput',
        name: 'Mark input',
        component: lazyLoad('modules/grade/MarkPage'),
        meta: {
          title: 'Nhập điểm',
          auth: true
        }
      },
      {
        path: 'markView',
        name: 'Mark input',
        component: lazyLoad('modules/grade/MarkViewPage'),
        meta: {
          title: 'Nhập điểm',
          auth: true
        }
      },
      {
        path: 'teachers',
        name: 'Teachers Page',
        component: lazyLoad('modules/grade/TeachersPage'),
        meta: {
          title: 'Giáo viên',
          auth: true
        }
      },
      {
        path: 'teacherAssign',
        name: 'Teachers Assign',
        component: lazyLoad('modules/grade/TeacherAssignPage'),
        meta: {
          title: 'Phân công',
          auth: true
        }
      },
      {
        path: 'monitorAssign',
        name: 'Monitor Assign',
        component: lazyLoad('modules/monitor/MonitorAssignPage'),
        meta: {
          title: 'Phân công',
          auth: true
        }
      },
      {
        path: 'absent',
        name: 'Absent List',
        component: lazyLoad('modules/absent-list/AbsentPage'),
        meta: {
          title: 'Biên bản thi',
          auth: true
        }
      },
      {
        path: 'code',
        name: 'Code Config',
        component: lazyLoad('modules/code-config/CodePage'),
        meta: {
          title: 'Số túi và mã phách',
          auth: true
        }
      }
    ]
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
