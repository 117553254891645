<template>
  <v-menu v-model="menu" open-on-hover offset-y>
    <template v-slot:activator="{on}">
      <v-btn text v-on="on">
        <div class="d-none d-sm-flex">
          {{ user.username || 'Default Name' }}
        </div>
      </v-btn>
    </template>
    <v-card>
      <v-list>
        <v-list-item :to="'/admin/accountdetail'">
          <v-list-item-content>
            <v-list-item-title>{{ user.username || 'Default Name' }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item>
          <v-btn text @click="onSignout()">Đăng xuất</v-btn>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  data() {
    return {
      menu: null
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    isXsScreen() {
      return this.$vuetify.breakpoint.xs
    }
  },
  methods: {
    ...mapActions('auth', ['signOut']),
    onSignout() {
      this.signOut()
      console.log('here')
      this.$router.push('/admin/signin')
    }
  }
}
</script>

<style scoped></style>
