import {Subject} from '@/plugins/api'
/* eslint-disable no-unused-vars */
export default {
  namespaced: true,
  actions: {
    async fetchSubjects({commit}, options) {
      const subjects = await Subject.fetch({...options})
      return subjects
    },
    async fetchSubject({commit}, id) {
      const subject = await Subject.fetchOne(id)
      return subject
    },
    async createSubject({commit}, data) {
      const newSubject = await Subject.create(data)
      return newSubject
    },
    async updateSubject({commit}, {id, ...subjectData}) {
      const updatedSubject = await Subject.update(id, {...subjectData})
      return updatedSubject
    }
  }
}
/* eslint-disable no-unused-vars */
