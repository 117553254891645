import axios from 'axios'
import utils from '@/plugins/utils'

const CV_API = '/cvs/'
const STUDENT_REVIEW_API = '/student-reviews/'
const ACTIVE_CODE_API = '/active-codes/'
const AUTH_API = '/auth/local'
const USER_API = '/users/'
const UPLOAD_API = '/upload/'
const DESTROY_API = '/upload/files/'
const NOTIFICATION_API = '/posts/'
const LEAD_API = '/leads/'
const LOG_API = '/logs/'
const APPOINTMENT_API = '/appointments/'
const PRODUCT_API = '/products/'
const ROLE_API = '/users-permissions/roles'
const PRODUCT_TYPE_API = '/product-types/'
const STATUS_COUNT = '/countStatus/'
const SUCCEED_COUNT = '/countSucceed/'
const POLICIES_API = '/policies/'
const EXAM_API = '/exams/'
const SUBJECT_API = '/subjects/'
const ROOM_API = '/rooms/'
const STUDENT_API = '/students/'
const STUDENT_EXAM_API = '/student-exams/'
const MARK_API = '/marks/'
const TEACHER_API = '/teachers/'
const TEACHER_EXAM_API = '/teacher-exams/'
const MONITOR_EXAM_API = '/monitor-exams/'
const ROOM_SUBJECT_API = '/room-subjects/'
const SUBJECT_GROUP_API = '/subject-groups/'
const EXAM_ROOM_API = '/exam-rooms/'
const INDEXING_API = '/indexing-progresses/'
const SMS_API = '/sent-sms/'
const EMAIL_API = '/sent-emails/'
const INFO_PROGRESS_API = '/info-progresses/'
const LEAD_PROGRESS_API = '/lead-progresses/'
const STUDENT_QUEUE_API = '/student-queues/'
const FORM_QUEUE_API = '/form-queues/'
const ENROLL_FORM_API = '/enroll-forms/'
const STUDENT_SERVICES_FORM_API = '/student-services-forms/'
const BASE_GRADE_API = '/base-grade/'

const APIHelper = api => ({
  search: (params, option) => axios.get(api, {params: utils.filterObject(params)}, option),
  count: (params, option) => axios.get(api + 'count', {params: utils.filterObject(params)}, option),
  fetch: (params, option) => axios.get(api, {params: utils.filterObject(params)}, option),
  fetchOne: (id, option) => axios.get(api + id, option),
  create: (params, options) => axios.post(api, utils.filterObject(params), options),
  update: (id, params, option) => axios.put(api + id, utils.filterObject(params), option),
  remove: (id, option) => axios.delete(api + id, option)
})
export const APIRespository = APIHelper
export const BaseGrade = {
  ...APIHelper(BASE_GRADE_API)
}
export const Auth = {
  ...APIHelper(AUTH_API),
  signIn: (params, options) => axios.post('/auth/local', utils.filterObject(params), options),
  signInAdmin: (params, options) => axios.post('/auth/log-in', utils.filterObject(params), options),
  requestResetOTP: userPhone =>
    axios.post(`/request-otp`, {
      userPhone,
      requestType: 'reset-password'
    }),
  sendEmail: data =>
    axios.post(`/users-permissions/sendEmail`, {
      ...data
    }),
  sendSubmitEmail: data =>
    axios.post(`/users-permissions/sendSubmitEmail`, {
      ...data
    }),
  sendSubmitForm2Email: data =>
    axios.post(`/users-permissions/sendSubmitForm2Email`, {
      ...data
    }),
  sendText: data =>
    axios.post(`/users-permissions/sendText`, {
      ...data
    }),
  confirmResetOTP: (userPhone, otp) =>
    axios.post(`/confirm-reset-password/${userPhone}`, {
      otp
    }),
  changePassword: (userPhone, newPassword, confirmNewPassword) =>
    axios.put(`/change-password/${userPhone}`, {
      newPassword,
      confirmNewPassword
    }),

  changeUserPassword: (id, oldPassword, password) =>
    axios.put(`/change-user-password/${id}`, {
      oldPassword,
      password
    }),
  register: (username, password, phone, email, cfToken, enrollmentGrade, name, userStudentData) =>
    axios.post('/users-permissions/regsiterAccount', {
      username,
      password,
      parentPhone: phone,
      email,
      cfToken,
      enrollmentGrade,
      name,
      studentData: userStudentData
    }),
  requestRegisterOTP: ({phone, username}) =>
    axios.post(`/request-otp`, {
      userPhone: phone,
      username,
      requestType: 'register'
    }),
  confirmSignupOTP: (userPhone, username, otp) =>
    axios.post(`/confirm-register`, {
      userPhone,
      username,
      otp
    })
}
export const Policies = {
  ...APIHelper(POLICIES_API),
  checkAuthPolicies: id => axios.get('/checkAuthPolicies/' + id)
}
export const CV = {
  ...APIHelper(CV_API),
  create: (parent, params) =>
    axios.post(`/cvs/${parent}`, {
      ...params
    }),
  update: (id, params) =>
    axios.put(`/cvs/${id}`, {
      ...params
    }),
  checkDocumentSystemTime: params =>
    axios.post(`/checkDocumentSystemTime`, {
      ...params
    }),
  checkSystemTime: () => axios.get(`/checkSystemTime`),
  checkServerTime: () => axios.get(`/checkServerTime`),
  startHourlySMSTask: () => axios.get(`/startHourlySMSTask`),
  stopHourlySMSTask: () => axios.get(`/stopHourlySMSTask`),
  createPaymentUrl: data => axios.post(`/createpaymenturl`, data),
  createPhase2Payment: data => axios.post(`/createPhase2Payment`, data),
  checkPhase2Status: data => axios.post(`/checkPhase2Status`, data),
  checkPaymentStatus: data => axios.post(`/checkpaymentstatus`, data),
  createEnrollPayment: data => axios.post(`/createEnrollPayment`, data),
  checkEnrollPaymentStatus: data => axios.post(`/checkEnrollPaymentStatus`, data),
  updateResults: data => axios.post(`/updateResults`, data),
  checkAvailable: params => axios.get('/checkAvailable', {params: utils.filterObject(params)}),
  updateAppliedTo: data => axios.post(`/updateAppliedTo`, data),
  updateHighAcceptedTo: data => axios.post(`/updateHighAcceptedTo`, data),
  updateHighAppliedTo: data => axios.post(`/updateHighAppliedTo`, data),
  removeQid: data => axios.post(`/removeQid`, data),
  cancelForm: data => axios.post(`/cancelForm`, data),
  cancelCV: data => axios.post(`/cancelCV`, data),
  updateSubmitDocument: data => axios.post(`/updateSubmitDocument`, data),
  submitDocument: data => axios.post(`/submitDocument`, data),
  submitDocument10: data => axios.post(`/submitDocument10`, data),
  countCVs: params => axios.get('/countCVs', {params: utils.filterObject(params)}),
  updateHighExamGrade: data => axios.put(`/updateHighExamGrade`, {...data})
}
export const StudentQueue = {
  ...APIHelper(STUDENT_QUEUE_API)
}
export const FormQueue = {
  ...APIHelper(FORM_QUEUE_API)
}
export const EnrollForm = {
  ...APIHelper(ENROLL_FORM_API),
  createHigh: data => {
    return axios.post(`/createHigh`, {...data})
  },
  updateHigh: data => {
    return axios.post(`/updateHigh`, {...data})
  },
  confirmApp: data => {
    return axios.post(`/confirmApp`, {...data})
  },
  getEnrollExam: data => {
    return axios.post(`/getEnrollExam`, {...data})
  },
  hasForm: data => {
    return axios.post(`/hasForm`, {...data})
  }
}
export const StudentServices = {
  ...APIHelper(STUDENT_SERVICES_FORM_API),
  updateServices6: data => {
    return axios.post(`/updateServices6`, {...data})
  },
  updateServices10: data => {
    return axios.post(`/updateServices10`, {...data})
  },
  adminService: data => {
    return axios.post(`/adminService`, {...data})
  },
  adminSubject: data => {
    return axios.post(`/adminSubject`, {...data})
  }
}
export const StudentReview = {
  ...APIHelper(STUDENT_REVIEW_API)
}
export const ActiveCode = {
  ...APIHelper(ACTIVE_CODE_API),
  validate: code =>
    axios.post(`/active-codes/validate`, {
      code
    }),
  enableActiveCode: code => axios.put(`/active-codes/enable-code/${code}`, {}),
  disableActiveCode: code => axios.put(`/active-codes/disable-code/${code}`, {})
}
export const User = APIHelper(USER_API)
export const Upload = {
  upload: formData =>
    axios.post(UPLOAD_API, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }),
  destroy: id => axios.delete(DESTROY_API + id)
}
export const Notification = APIHelper(NOTIFICATION_API)
export const Lead = {
  ...APIHelper(LEAD_API),
  createManyLead: leads => {
    return axios.post(`/createMany`, {leads})
  }
}
export const LeadProgress = {
  ...APIHelper(LEAD_PROGRESS_API)
}
export const Log = {
  ...APIHelper(LOG_API)
}
export const Appointment = {
  ...APIHelper(APPOINTMENT_API)
}
export const Product = {
  ...APIHelper(PRODUCT_API)
}
export const ProductType = {
  ...APIHelper(PRODUCT_TYPE_API)
}
export const CountStatus = {
  ...APIHelper(STATUS_COUNT)
}
export const CountSucceed = {
  ...APIHelper(SUCCEED_COUNT)
}
export const Role = {
  ...APIHelper(ROLE_API),
  updateRole: (id, params) => axios.put('/users-permissions/updateRole/' + id, params),
  queryRoles: params => axios.get('/users-permissions/queryRoles', {params: utils.filterObject(params)})
}
export const Exam = {
  ...APIHelper(EXAM_API),
  examList: () => {
    return axios.get('/examList')
  },
  signUpExtra: data => {
    return axios.post(`/signUpExtra`, {...data})
  }
}
export const Subject = {
  ...APIHelper(SUBJECT_API)
}
export const Room = {
  ...APIHelper(ROOM_API),
  createManyRooms: data => {
    return axios.post(`/rooms/createMany`, {...data})
  },
  excelRooms: data => {
    return axios.post('/rooms/excelRooms', {...data})
  }
}
export const Student = {
  ...APIHelper(STUDENT_API),
  studentFromCode: params => {
    return axios.get(`/studentFromCv`, {params: utils.filterObject(params)})
  },
  createAllMainExam: data => {
    return axios.post(`/createAllMainExam`, {...data})
  },
  createAllMainExam6: data => {
    return axios.post(`/createAllMainExam6`, {...data})
  },
  assignSubjectGroup: data => {
    return axios.post(`/assignSubjectGroup`, {...data})
  },
  assignSubjectGroupAll: data => {
    return axios.post(`/assignSubjectGroupAll`, {...data})
  },
  parentExamRegister: data => {
    return axios.post('/parentExamRegister', {...data})
  },
  mockCreatePaymentUrl: data => axios.post(`/mockCreatePaymentUrl`, data),
  mockCheckPaymentstatus: data => axios.post(`/mockCheckPaymentstatus`, data)
}
export const StudentExam = {
  ...APIHelper(STUDENT_EXAM_API),
  assignRooms: data => {
    return axios.post('/assignRooms', {...data})
  },
  fileRegister: data => {
    return axios.post('/fileRegister', {...data})
  },
  indexingStudent: data => axios.put(`/indexingStudent`, {...data}),
  indexingEmpty: data => axios.put(`/indexingEmpty`, {...data}),
  autoAssignRooms: data => axios.put(`/autoAssignRooms`, {...data}),
  autoAssignEmptyRooms: data => axios.put(`/autoAssignEmptyRooms`, {...data})
}
export const Mark = {
  ...APIHelper(MARK_API),
  updateGrades: data => axios.put(`/updateGrades`, {...data}),
  updateStatus: data => axios.put('/updateStatus', {...data}),
  getMarksData: params => {
    return axios.get(`/getMarksData`, {params: utils.filterObject(params)})
  },
  getMarksExcel: params => {
    return axios.get(`/getMarksExcel`, {params: utils.filterObject(params)})
  }
}
export const Teacher = {
  ...APIHelper(TEACHER_API)
}
export const TeacherExam = {
  ...APIHelper(TEACHER_EXAM_API)
}
export const MonitorExam = {
  ...APIHelper(MONITOR_EXAM_API)
}
export const RoomSubject = {
  ...APIHelper(ROOM_SUBJECT_API),
  assignTestCode: data => axios.put(`/assignTestCode`, {...data}),
  assignRoomCode: data => axios.put(`/assignRoomCode`, {...data}),
  assignDate: data => axios.put('/assignDate', {...data})
}
export const SubjectGroup = {
  ...APIHelper(SUBJECT_GROUP_API),
  updateMany: data => axios.put('/updateManyGroups', {...data})
}
export const ExamRoom = {
  ...APIHelper(EXAM_ROOM_API)
}
export const Indexing = {
  ...APIHelper(INDEXING_API)
}
export const Sms = {
  ...APIHelper(SMS_API),
  sendManyTexts: data => axios.post('/users-permissions/sendManyTexts', {...data})
}
export const Email = {
  ...APIHelper(EMAIL_API),
  sendManyEmails: data => axios.post('/users-permissions/sendManyEmails', {...data})
}
export const InfoProgress = {
  ...APIHelper(INFO_PROGRESS_API)
}
export default {
  Auth,
  CV,
  StudentQueue,
  Upload,
  User,
  ActiveCode,
  Notification,
  Lead,
  Log,
  Appointment,
  Product,
  ProductType,
  CountStatus,
  CountSucceed,
  Role,
  Policies,
  Exam,
  Subject,
  Room,
  Student,
  StudentExam,
  Mark,
  Teacher,
  TeacherExam,
  MonitorExam,
  RoomSubject,
  SubjectGroup,
  ExamRoom,
  Sms,
  InfoProgress,
  Indexing,
  StudentReview,
  EnrollForm,
  StudentServices,
  BaseGrade,
  FormQueue
}
