import {Indexing} from '@/plugins/api'
import alert from '@/plugins/alert'

/* eslint-disable no-unused-vars */
export default {
  namespaced: true,
  actions: {
    async fetchIndexings({commit}, options) {
      const indexings = await Indexing.fetch({...options})
      return indexings
    },
    async fetchIndexing({commit}, id) {
      const indexing = await Indexing.fetchOne(id)
      return indexing
    },
    async createIndexing({commit}, data) {
      return await Indexing.create(data)
    },
    async updateIndexing({commit}, {id, ...data}) {
      const updatedGroup = await Indexing.update(id, {...data})
      return updatedGroup
    },
    async countIndexings({commit}, options) {
      try {
        return await Indexing.count(options)
      } catch (e) {
        alert.error(e)
      }
    }
  }
}
/* eslint-disable no-unused-vars */
