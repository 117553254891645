<template>
  <v-dialog v-model="dialog" width="480px" persistent>
    <v-card>
      <v-card-title
        ><div class="title--text">Đăng nhập</div>
        <v-spacer />
        <v-icon @click="cancel()" class="mr-n1">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-4 pb-2">
        <v-form ref="form" v-model="isValid">
          <div class="text-subtitle-1">
            Mã học sinh (của Bộ GD&ĐT, trên phần mềm CSDL ngành)<span style="color: red">*</span>
          </div>
          <v-text-field
            placeholder="Nhập mã định danh tại đây (Ví dụ: 1231231231)"
            v-model="credentials.identifier"
            name="login"
            type="tel"
            color="primary"
            v-mask="'##########'"
            @keyup.enter="submit"
            outlined
            validate-on-blur
          />
          <div class="text-subtitle-1">Mật khẩu <span style="color: red">*</span></div>
          <v-text-field
            placeholder="Nhập mật khẩu tại đây"
            v-model="credentials.password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="passwordRules"
            @click:append="showPassword = !showPassword"
            @keyup.enter="submit"
            @keyup="$refs.form.validate()"
            color="primary"
            outlined
            validate-on-blur
          />
        </v-form>
        <v-btn
          depressed
          x-large
          color="primary"
          class="white--text text-subtitle-1 text-none mt-6"
          style="width: 100%"
          :disabled="!isValid"
          :loading="loading"
          @click="submit()"
          >Đăng nhập
        </v-btn>
        <div class="d-flex justify-center mt-6">
          <v-btn
            plain
            class="
              info--text
              text-subtitle-1 text-decoration-underline text-none
            "
            style="width: 100%"
            @click="resetPassword()"
            >Quên mật khẩu?
          </v-btn>
        </div>
        <v-btn
          plain
          color="primary"
          class="text-subtitle-1 font-weight-bold text-none mt-3"
          style="width: 100%"
          @click="register()"
          >Chưa có tài khoản? Đăng ký
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
export default {
  watch: {
    signInDialog(signInDialog) {
      this.dialog = signInDialog
    }
  },
  computed: {
    ...mapGetters('layout', ['signInDialog']),
    ...mapGetters('auth', ['isAuthenticated', 'user', 'isRequestingReset', 'isConfirmedResetOTP', 'isConfirmedOTP'])
  },
  data() {
    return {
      isValid: true,
      dialog: false,
      credentials: {
        identifier: '',
        password: ''
      },
      loading: false,
      showPassword: false,
      password: 'Password',
      phoneRules: [this.$rules.required, this.$rules.phone],
      passwordRules: [this.$rules.required, this.$rules.minLength(4)]
    }
  },
  methods: {
    ...mapActions('auth', ['signIn']),
    ...mapActions('layout', [
      'setSignInDialog',
      'setSignUpDialog',
      'setForgotPasswordDialog',
      'setConfirmSignupDialog',
      'setConfirmForgotPasswordDialog',
      'setNewPasswordDialog',
      'setDocumentDialog',
      'setCountdownDialog'
    ]),
    cancel() {
      this.$refs.form.reset()
      this.setSignInDialog(false)
    },
    delay(time) {
      return new Promise(rel => {
        setTimeout(() => {
          rel()
        }, time)
      })
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true
        await this.delay(1500)
        await this.signIn(this.credentials)
        if (this.user && this.isAuthenticated && !this.isConfirmedOTP) {
          this.$refs.form.reset()
          this.setSignInDialog(false)
          this.setConfirmSignupDialog(true)
        } else if (this.user && this.isAuthenticated) {
          this.$refs.form.reset()
          this.setSignInDialog(false)
        }
        this.loading = false
      }
    },
    resetPassword() {
      this.setSignInDialog(false)
      if (this.isRequestingReset) this.setConfirmForgotPasswordDialog(true)
      else if (this.isConfirmedResetOTP) this.setNewPasswordDialog(true)
      else this.setForgotPasswordDialog(true)
    },
    register() {
      this.setSignInDialog(false)
      if (this.user && this.isAuthenticated && !this.isConfirmedOTP) this.setConfirmSignupDialog(true)
      else this.setSignUpDialog(true)
    }
  }
}
</script>
