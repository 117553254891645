<template>
  <div>
    <v-btn
      class="text-none"
      v-if="isOpen"
      color="primary"
      :class="{'mr-4 btn-text': $vuetify.breakpoint.mdAndUp}"
      @click="onSigninClick"
      depressed
      light
      dense
      text
      large
      rounded
      >Đăng nhập</v-btn
    >
    <v-btn
      color="orange"
      class="text-none white--text"
      v-if="isOpen"
      :class="{'mr-4 btn-text': $vuetify.breakpoint.mdAndUp}"
      @click="onSignupClick"
      elevation="0"
      dense
      rounded
      large
      >Đăng ký TS Khối 6</v-btn
    >
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
export default {
  props: {
    isOpen: Boolean
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated', 'user', 'isConfirmedOTP'])
  },
  methods: {
    ...mapActions('layout', ['setSignUpDialog', 'setSignInDialog', 'setAllDialogClose', 'setConfirmSignupDialog']),
    onSigninClick() {
      this.setAllDialogClose()
      this.setSignInDialog(true)
    },
    onSignupClick() {
      this.setAllDialogClose()
      if (this.isAuthenticated && this.user && !this.isConfirmedOTP) this.setConfirmSignupDialog(true)
      else this.setSignUpDialog(true)
    }
  }
}
</script>
<style scoped>
.btn-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
</style>
