/* eslint-disable no-unused-vars */
import alert from '@/plugins/alert'
import {CV} from '@/plugins/api'
import moment from 'moment'
export default {
  namespaced: true,
  state: {
    CVs: [],
    count: 0,
    step: 1,
    systemTime: {
      checkSystemTime: {
        'open-document': false,
        'grade6-close-create': false,
        'grade10-close-create': false,
        'grade6-close-submit': false,
        'grade10-close-submit': false
      },
      systemTime: {
        'open-document': moment().format('DD/MM/YYYY HH:mm:ss')
      }
    }
  },
  actions: {
    async startHourlySMSTask() {
      try {
        await CV.startHourlySMSTask()
        alert.success('Start Hourly SMS task successfully!')
      } catch (e) {
        alert.error(e)
      }
    },
    async stopHourlySMSTask() {
      try {
        await CV.startHourlySMSTask()
        alert.success('Stop Hourly SMS task successfully!')
      } catch (e) {
        alert.error(e)
      }
    },
    async checkSystemTime({commit}) {
      try {
        const systemTime = await CV.checkSystemTime()
        commit('setSystemTime', systemTime)
        return systemTime
      } catch (e) {
        alert.error(e)
      }
    },
    async checkDocumentSystemTime({state}, {grade}) {
      try {
        return await CV.checkDocumentSystemTime({grade})
      } catch (e) {
        alert.error(e)
      }
    },
    async fetchCVs({commit}, options) {
      try {
        const cvs = await CV.fetch({...options})
        commit('setCVs', cvs)
        return cvs
      } catch (e) {
        alert.error(e)
      }
    },
    async getCvs({commit}, options) {
      try {
        const cvs = await CV.fetch({...options, _limit: -1})
        return cvs
      } catch (e) {
        alert.error(e)
      }
    },
    async countCVs({commit}, options) {
      try {
        return await CV.count(options)
      } catch (e) {
        alert.error(e)
      }
    },
    async fetchCV({commit}, {...item}) {
      try {
        const cv = await CV.search({...item})
        if (cv.length > 0) {
          commit('setCV', cv[0])
          commit('setStep', cv[0].step)
          return cv[0]
        }
      } catch (e) {
        alert.error(e)
      }
    },
    async createCV({commit}, {parent, ...data}) {
      try {
        const newCV = await CV.create(parent, data)
        commit('setCV', newCV)
        alert.success('Tạo hồ sơ mới thành công!')
        return newCV
      } catch (e) {
        alert.error(e)
      }
    },
    async updateCV({commit, state}, {id, ...Cv}) {
      try {
        const updatedCV = await CV.update(id, Cv)
        commit('setCV', updatedCV)
        return updatedCV
      } catch (e) {
        alert.error(e)
      }
    },
    async removeCV({commit}, id) {
      try {
        const removedCV = await CV.remove(id)
        commit('removeCV', removedCV.code)
        alert.success('Xóa hồ sơ thành công!')
      } catch (e) {
        alert.error(e)
      }
    },
    setCV({commit}, Cv) {
      commit('setCV', Cv)
    },
    setStep({commit, state}, step) {
      commit('setStep', step)
    },
    async removeCVs({dispatch}, items) {
      for (let item of items) {
        await dispatch('removeCV', item.code)
      }
    },
    setCVs({commit}, cvs) {
      commit('setCVs', cvs)
    }
  },
  mutations: {
    setCVs(state, CVs) {
      state.CVs = CVs.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [`${currentValue.id}`]: currentValue
        }),
        {}
      )
    },
    setCV(state, CV) {
      state.CVs = {
        ...state.CVs,
        [CV.id]: CV
      }
    },
    setStep(state, step) {
      state.step = step
    },
    setSystemTime(state, systemTime) {
      state.systemTime = systemTime
    },
    removeCV(state, id) {
      delete state.CVs[id]
      state.CVs = {...state.CVs}
    }
  },
  getters: {
    systemTime: state => {
      return state.systemTime
    },
    CVs: state => {
      return Object.values(state.CVs)
    },
    CV: state => id => {
      return state.CVs[id]
    },
    count: state => {
      return state.count
    },
    step: state => {
      return state.step
    }
  }
}
/* eslint-enable no-unused-vars */
