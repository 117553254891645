import { render, staticRenderFns } from "./MainToolbar.vue?vue&type=template&id=54fd6610"
import script from "./MainToolbar.vue?vue&type=script&lang=js"
export * from "./MainToolbar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('54fd6610')) {
      api.createRecord('54fd6610', component.options)
    } else {
      api.reload('54fd6610', component.options)
    }
    module.hot.accept("./MainToolbar.vue?vue&type=template&id=54fd6610", function () {
      api.rerender('54fd6610', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/admin/MainToolbar.vue"
export default component.exports