/**
 * Store quản lý danh sách các role trong hệ thống
 */

import {Role} from '@/plugins/api'
import alert from '@/plugins/alert'

export default {
  namespaced: true,
  state: {
    role: {},
    roles: []
  },
  actions: {
    async fetchRoles({commit}, options) {
      try {
        const {roles = []} = await Role.fetch({_limit: -1, ...options})
        commit('setRoles', roles)
        return roles
      } catch (error) {
        console.error(error)
      }
    },
    async updateRolePolicies({state, commit}, {id, policies}) {
      try {
        const response = await Role.updateRole(id, {policies})
        if (response.ok) {
          const newRoles = state.roles.map(role => {
            if (role.id === id) {
              role.policies = policies
            }
            return role
          })
          commit('setRoles', newRoles)
          alert.success('Cập nhập phân quyền thành công')
        } else {
          alert.error('Cập nhật phân quyền thất bại')
        }
      } catch (error) {
        alert.error('Cập nhật phân quyền thất bại')
        console.error(error)
      }
    },
    setRole({commit}, role) {
      commit('setRole', role)
    }
  },
  mutations: {
    setRoles(state, roles) {
      state.roles = roles
    },
    setRole(state, role) {
      state.role = role
    }
  },
  getters: {
    role: state => state.role,
    roleIdByName: state => name => {
      const {id = ''} = state.roles.find(role => role.name === name) || {}
      return id
    }
  }
}
