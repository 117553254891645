/* eslint-disable no-unused-vars */
import alert from '@/plugins/alert'
import {Appointment} from '@/plugins/api'
export default {
  namespaced: true,
  state: {
    Leads: [],
    count: 0
  },
  actions: {
    async fetchAppointments({commit}, options) {
      const appointments = await Appointment.fetch({...options})
      return appointments
    },
    async fetchAppointment({commit}, appId) {
      const appointment = await Appointment.fetchOne(appId)
      return appointment
    },

    async createAppointment({commit}, data) {
      const newApp = await Appointment.create(data)
      return newApp
    }
  }
}

/* eslint-enable no-unused-vars */
