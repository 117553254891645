import {Sms, InfoProgress} from '../../plugins/api'
/* eslint-disable no-unused-vars */

export default {
  namespaced: true,
  actions: {
    async fetchSms({commit}, options) {
      const sms = await Sms.fetch({...options})
      return sms
    },
    async countSms({commit}, options) {
      const count = await Sms.count({...options})
      return count
    },
    async fetchSmsProgress({commit}, options) {
      const progress = await InfoProgress.fetch({...options, type: 'sms'})
      return progress[0]
    },
    async updateSmsProgress({commit}, {id, ...data}) {
      const updateProg = await InfoProgress.update(id, {...data})
      return updateProg
    }
  }
}
/* eslint-disable no-unused-vars */
