var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isOpen
        ? _c(
            "v-btn",
            {
              staticClass: "text-none",
              class: { "mr-4 btn-text": _vm.$vuetify.breakpoint.mdAndUp },
              attrs: {
                color: "primary",
                depressed: "",
                light: "",
                dense: "",
                text: "",
                large: "",
                rounded: ""
              },
              on: { click: _vm.onSigninClick }
            },
            [_vm._v("Đăng nhập")]
          )
        : _vm._e(),
      _vm.isOpen
        ? _c(
            "v-btn",
            {
              staticClass: "text-none white--text",
              class: { "mr-4 btn-text": _vm.$vuetify.breakpoint.mdAndUp },
              attrs: {
                color: "orange",
                elevation: "0",
                dense: "",
                rounded: "",
                large: ""
              },
              on: { click: _vm.onSignupClick }
            },
            [_vm._v("Đăng ký TS Khối 6")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }