/* eslint-disable no-unused-vars */
import alert from "@/plugins/alert";
import { ActiveCode } from "@/plugins/api";
export default {
  namespaced: true,
  state: {
    ActiveCodes: [],
    count: 0,
  },
  actions: {
    async enableActiveCode({commit}, code){
      try {
        alert.success(await ActiveCode.enableActiveCode(code));
      } catch (e) {
        alert.error(e);
      }
    },
    async disableActiveCode({commit}, code){
      try {
        alert.success(await ActiveCode.disableActiveCode(code));
      } catch (e) {
        alert.error(e);
      }
    },
    async validate({ commit }, code) {
      try {
        return await ActiveCode.validate(code);
      } catch (e) {
        alert.error(e);
      }
    },
    async fetchActiveCodes({ commit }, options) {
      try {
        commit(
          "setActiveCodes",
          await ActiveCode.fetch({ ...options, _limit: -1 })
        );
      } catch (e) {
        alert.error(e);
      }
    },
    async countActiveCodes({ commit }, options) {
      try {
        return await ActiveCode.count(options);
      } catch (e) {
        alert.error(e);
      }
    },
    async fetchActiveCode({ commit }, code) {
      try {
        const activeCode = await ActiveCode.search({ code });
        commit("setActiveCode", activeCode[0]);
        return activeCode[0];
      } catch (e) {
        alert.error(e);
      }
    },
    async createActiveCode({ commit }, data) {
      try {
        const activeCode = await ActiveCode.create(data);
        commit("setActiveCode", activeCode);
        return activeCode;
      } catch (e) {
        alert.error(e);
      }
    },
    async updateActiveCode({ commit }, { id, ...activeCode }) {
      try {
        commit("setActiveCode", await ActiveCode.update(id, activeCode));
      } catch (e) {
        alert.error(e);
      }
    },
    async removeActiveCode({ commit }, id) {
      try {
        await ActiveCode.remove(id);
        commit("removeActiveCode", id);
        alert.success("Xóa mã hồ sơ thành công!");
      } catch (e) {
        alert.error(e);
      }
    },
    setActiveCode({ commit, state }, ActiveCode) {
      commit("setActiveCode", ActiveCode);
    },
    async removeActiveCodes({ dispatch }, items) {
      for (let item of items) {
        await dispatch("removeActiveCode", item.id);
      }
    },
  },
  mutations: {
    setActiveCodes(state, ActiveCodes) {
      state.ActiveCodes = ActiveCodes.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [currentValue.id]: currentValue,
        }),
        {}
      );
    },
    setActiveCode(state, ActiveCode) {
      state.ActiveCodes = {
        ...state.ActiveCodes,
        [ActiveCode.id]: ActiveCode,
      };
    },
    removeActiveCode(state, ActiveCodeId) {
      delete state.ActiveCodes[ActiveCodeId];
      state.ActiveCodes = { ...state.ActiveCodes };
    },
  },
  getters: {
    activeCodes: (state) => {
      return Object.values(state.ActiveCodes);
    },
    activeCode: (state) => (id) => {
      return state.ActiveCodes[id];
    },
    count: (state) => {
      return state.count;
    },
  },
};
/* eslint-enable no-unused-vars */
