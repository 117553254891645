import {ExamRoom} from '@/plugins/api'
import alert from '@/plugins/alert'

/* eslint-disable no-unused-vars */
export default {
  namespaced: true,
  actions: {
    async fetchExamRooms({commit}, options) {
      const examRooms = await ExamRoom.fetch({...options})
      return examRooms
    },
    async fetchExamRoom({commit}, id) {
      const examRoom = await ExamRoom.fetchOne(id)
      return examRoom
    },
    async createExamRoom({commit}, data) {
      return await ExamRoom.create(data)
    },
    async updateExamRoom({commit}, {id, ...data}) {
      const updatedGroup = await ExamRoom.update(id, {...data})
      return updatedGroup
    },
    async countExamRooms({commit}, options) {
      try {
        return await ExamRoom.count(options)
      } catch (e) {
        alert.error(e)
      }
    }
  }
}
/* eslint-disable no-unused-vars */
