import {Exam} from '@/plugins/api'
/* eslint-disable no-unused-vars */
export default {
  namespaced: true,
  actions: {
    async fetchExams({commit}, options) {
      const exams = await Exam.fetch({...options})
      return exams
    },
    async fetchExam({commit}, id) {
      const exam = await Exam.fetchOne(id)
      return exam
    },
    async createExam({commit}, data) {
      const newExam = await Exam.create(data)
      return newExam
    },
    async updateExam({commit}, {id, ...examData}) {
      const updatedExam = await Exam.update(id, {...examData})
      return updatedExam
    }
  }
}
/* eslint-disable no-unused-vars */
