import {Student, StudentExam} from '@/plugins/api'
import alert from '@/plugins/alert'

/* eslint-disable no-unused-vars */
export default {
  namespaced: true,
  actions: {
    async fetchStudents({commit}, options) {
      const students = await Student.fetch({...options})
      return students
    },
    async fetchStudentExams({commit}, options) {
      const studentExams = await StudentExam.fetch({...options})
      return studentExams
    },
    async fetchStudent({commit}, id) {
      const student = await Student.fetchOne(id)
      return student
    },
    async fetchStudentExam({commit}, id) {
      const stuEx = await StudentExam.fetchOne(id)
      return stuEx
    },
    async createStudent({commit}, data) {
      const newStu = await Student.create(data)
      return newStu
    },
    async createStudentExam({commit}, data) {
      const newStuEx = await StudentExam.create(data)
      return newStuEx
    },
    async updateStudent({commit}, {id, ...stuData}) {
      const updatedStudent = await Student.update(id, {...stuData})
      return updatedStudent
    },
    async updateStudentExam({commit}, {id, ...data}) {
      const updatedStuEx = await StudentExam.update(id, {...data})
      return updatedStuEx
    },
    async countStudents({commit}, options) {
      try {
        return await Student.count(options)
      } catch (e) {
        alert.error(e)
      }
    },
    async countStudentExams({commit}, options) {
      try {
        return await StudentExam.count(options)
      } catch (e) {
        alert.error(e)
      }
    },
    async removeStudentExam({commit}, id) {
      await StudentExam.remove(id)
      alert.success('Bỏ học sinh ra khỏi kỳ thi thành công')
    }
  }
}
/* eslint-disable no-unused-vars */
