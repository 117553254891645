<template>
  <v-dialog v-model="dialog" max-width="640px" persistent scrollable>
    <v-card>
      <v-card-title
        ><div class="title--text">Đăng ký</div>
        <v-spacer />
        <v-icon class="mr-n1" @click="cancel()">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-4 pb-2">
        <v-form ref="form">
          <div class="text-subtitle-1">Đăng ký tuyển sinh Khối 6<span style="color: red">*</span></div>
          <v-radio-group v-model="enrollmentGrade" column :rules="[$rules.required]" class="ma-0">
            <v-radio label="Khối 6" value="secondary"></v-radio>
            <!-- <v-radio label="Khối 10" value="high"></v-radio> -->
          </v-radio-group>
          <div class="text-subtitle-1">Họ tên học sinh <span style="color: red">*</span></div>
          <v-text-field
            placeholder="VD: Nguyễn Văn A"
            name="login"
            color="primary"
            v-model="studentName"
            :rules="[$rules.required]"
            @keyup.enter="submit"
            outlined
          />
          <div class="text-subtitle-1">Ngày tháng năm sinh <span style="color: red">*</span></div>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{on, attrs}">
              <v-text-field
                :value="formattedDOB"
                placeholder="Chọn ngày sinh"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="[$rules.required]"
                outlined
              ></v-text-field>
            </template>
            <v-date-picker v-model="dob" @input="menu = false"></v-date-picker>
          </v-menu>

          <div class="text-subtitle-1">
            Mã học sinh (của Bộ GD&ĐT, trên phần mềm CSDL ngành)<span style="color: red">*</span>
          </div>
          <div>
            <span> Quý PH vui lòng xem cách lấy mã học sinh</span
            ><a
              href="https://d27ehxdk832fc9.cloudfront.net/HƯỚNG DẪN PHHS CÁCH XEM MÃ ĐỊNH DANH HỌC SINH DO BỘ GIÁO DỤC CẤP DỤC VÀ ĐÀO TẠP CẤP.pdf"
              target="_blank"
              style="color: #1976D2;"
            >
              Tại đây
            </a>
          </div>
          <v-text-field
            placeholder="Nhập mã học sinh tại đây"
            name="login"
            color="primary"
            v-mask="'##########'"
            v-model="studentCode"
            @keyup.enter="submit"
            :rules="[$rules.required, $rules.minLength(10)]"
            outlined
          />

          <div class="text-subtitle-1">Trường con đang theo học <span style="color: red">*</span></div>
          <v-combobox
            :items="schoolOptions"
            v-model="studentSchool"
            item-text="name"
            item-value="name"
            :menu-props="{maxHeight: 120}"
            outlined
            clearable
            :return-object="false"
            :rules="[$rules.required]"
            autocomplete="on"
            placeholder="Bắt đầu gõ để tìm kiếm..."
          ></v-combobox>

          <div class="text-subtitle-1">
            Nguyện vọng trường cấp {{ enrollmentGrade === 'secondary' ? '2' : '3' }} <span style="color: red">*</span>
            <span style="color: #707070; font-size: 12px;"> (Dự kiến)</span>
          </div>

          <v-combobox
            :items="expectOptions"
            v-model="expectSchool"
            item-text="name"
            item-value="name"
            outlined
            :menu-props="{maxHeight: 120}"
            clearable
            autocomplete="on"
            :return-object="false"
            placeholder="Bắt đầu gõ để tìm kiếm..."
            :rules="[$rules.required]"
          ></v-combobox>

          <div class="text-subtitle-1">Họ tên phụ huynh <span style="color: red">*</span></div>
          <v-text-field
            placeholder="Nhập tên phụ huynh tại đây"
            name="login"
            color="primary"
            v-model="parentName"
            :rules="[$rules.required]"
            outlined
          />
          <div class="text-subtitle-1">Số điện thoại PH <span style="color: red">*</span></div>
          <v-text-field
            placeholder="Nhập số điện thoại tại đây (Ví dụ: 097372xxxx)"
            name="login"
            type="tel"
            color="primary"
            v-mask="'##########'"
            v-model="phone"
            @keyup.enter="submit"
            :rules="phoneRules"
            outlined
            validate-on-blur
          />
          <div class="text-subtitle-1">Email liên hệ <span style="color: red">*</span></div>
          <v-text-field
            placeholder="VD: abcxyz@gmail.com"
            name="login"
            color="primary"
            v-model="parentEmail"
            :rules="[$rules.required, $rules.email]"
            outlined
          />
          <v-divider></v-divider>
          <div class="text-subtitle-1" style="margin-top: 22px;">Mật khẩu <span style="color: red">*</span></div>
          <v-text-field
            placeholder="Nhập mật khẩu tại đây"
            v-model="password"
            color="primary"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="showPassword = !showPassword"
            @keyup.enter="submit"
            outlined
            validate-on-blur
          />
          <div class="text-subtitle-1">Mật khẩu (nhắc lại) <span style="color: red">*</span></div>
          <v-text-field
            placeholder="Nhập mật khẩu tại đây"
            v-model="confirmPassword"
            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showConfirmPassword ? 'text' : 'password'"
            :rules="confirmPasswordRules"
            @click:append="showConfirmPassword = !showConfirmPassword"
            @keyup.enter="submit"
            @keyup="$refs.form.validate()"
            color="primary"
            outlined
            validate-on-blur
          />
        </v-form>
        <div class="cf-turnstile" data-sitekey="0x4AAAAAAAQ-PRt09feV7Bnr"></div>
        <!-- <div class="cf-turnstile" data-sitekey="1x00000000000000000000AA"></div> -->
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions style="flex-direction: column;">
        <div style="width: 100%;">
          <v-btn
            depressed
            x-large
            color="primary"
            class="white--text text-subtitle-1 mt-4 text-none"
            style="width: 100%"
            :loading="loading"
            :disabled="!token"
            @click="submit"
            >Đăng ký</v-btn
          >
        </div>
        <div style="width: 100%;">
          <v-btn
            plain
            x-large
            color="primary"
            class="text-subtitle-1 font-weight-bold mt-2 text-none"
            style="width: 100%"
            @click="signin()"
            >Đã có tài khoản? Đăng nhập</v-btn
          >
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
/* eslint-disable no-unused-vars */
import {mapActions, mapGetters} from 'vuex'
import eleSchool from '../../assets/ele_school.json'
import secondarySchool from '../../assets/secondary_school.json'
import highSchool from '../../assets/high_school.json'
import moment from 'moment'
import utils from '@/plugins/utils'
export default {
  watch: {
    signUpDialog(signUpDialog) {
      this.dialog = signUpDialog
    },
    enrollmentGrade(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.studentSchool = ''
      }
    },
    dialog: {
      handler(val) {
        if (val === true) {
          this.token = ''
          this.$nextTick(() => this.renderCF())
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('layout', ['signUpDialog']),
    ...mapGetters('auth', ['isAuthenticated', 'user']),
    schoolOptions() {
      if (this.enrollmentGrade === 'secondary') {
        return eleSchool
      } else if (this.enrollmentGrade === 'high') {
        return secondarySchool
      }
      return []
    },
    expectOptions() {
      if (this.enrollmentGrade === 'secondary') {
        return secondarySchool
      } else if (this.enrollmentGrade === 'high') {
        return highSchool
      }
      return []
    },
    formattedDOB() {
      return this.dob ? moment(this.dob).format('DD/MM/YYYY') : ''
    }
  },
  data() {
    return {
      dialog: false,
      isValid: true,
      phone: '',
      parentName: '',
      studentName: '',
      studentSchool: '',
      expectSchool: '',
      parentEmail: '',
      dob: '',
      menu: false,
      token: '',
      confirmPassword: '',
      studentCode: '',
      enrollmentGrade: 'secondary',
      loading: false,
      showPassword: false,
      cfWidget: null,
      showConfirmPassword: false,
      password: '',
      phoneRules: [this.$rules.required, this.$rules.phone],
      passwordRules: [this.$rules.required, this.$rules.minLength(4)],
      confirmPasswordRules: [
        this.$rules.required,
        this.$rules.minLength(4),
        v => (v && v === this.password) || 'Mật khẩu (nhập lại) không trùng khớp với mật khẩu.'
      ],
      emailRules: [this.$rules.required, this.$rules.email]
    }
  },
  methods: {
    ...mapActions('auth', ['signUp']),
    ...mapActions('lead', ['createLead']),
    ...mapActions('layout', ['setSignUpDialog', 'setSignInDialog', 'setConfirmSignupDialog']),
    cancel() {
      this.$refs.form.reset()
      this.setSignUpDialog(false)
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true
        const emailData = {
          parentEmail: this.parentEmail,
          parentName: this.parentName,
          studentCode: this.studentCode,
          studentName: this.studentName,
          dob: this.dob,
          studentSchool: this.studentSchool,
          parentPhone: this.phone
        }
        const userStudentData = {
          name: this.studentName,
          dob: this.dob,
          school: this.studentSchool,
          expectSchool: this.expectSchool
        }
        const studentData = {student: [{name: this.studentName, dob: this.dob, school: this.studentSchool}]}
        const leadData = {
          phone: this.phone,
          buyerName: this.parentName,
          email: this.parentEmail,
          createdByName: 'parent',
          expectSchool: this.expectSchool,
          studentExpectSchoolSearchField: utils.removeUnicode(this.expectSchool).toLowerCase(),
          studentSchoolSearchField: utils.removeUnicode(this.studentSchool).toLowerCase(),
          studentNameSearchField: utils.removeUnicode(this.studentName).toLowerCase(),
          type: ['hasAccount', 'noCV'],
          data: studentData
        }
        const user = await this.signUp({
          username: this.studentCode,
          password: this.password,
          phone: this.phone,
          email: this.parentEmail,
          name: this.parentName,
          userStudentData,
          cfToken: this.token,
          enrollmentGrade: this.enrollmentGrade,
          emailData
        })
        if (user) {
          await this.createLead({
            ...leadData
          })
        }

        if (this.user && this.isAuthenticated) {
          this.$refs.form.reset()

          this.setSignUpDialog(false)
          this.setConfirmSignupDialog(true)
        } else {
          this.resetTurnstile()
        }
        this.loading = false
      }
    },
    signin() {
      this.setSignInDialog(true)
      this.setSignUpDialog(false)
    },
    renderCF() {
      this.token = ''
      const cfContainer = document.querySelector('.cf-turnstile')
      if (cfContainer) {
        cfContainer.innerHTML = ''
      }

      if (window.turnstile) {
        window.turnstile.render('.cf-turnstile', {
          // sitekey: '1x00000000000000000000AA',
          sitekey: '0x4AAAAAAAQ-PRt09feV7Bnr',
          theme: 'light',
          callback: token => {
            if (token) {
              console.log(token)
              this.token = token
            }
          }
        })
      }
    },
    resetTurnstile() {
      const turnstileContainer = document.querySelector('.cf-turnstile')
      if (turnstileContainer) {
        turnstileContainer.innerHTML = ''
        this.renderCF()
      }
    }
  }
}
/* eslint-enable no-unused-vars */
</script>

<style>
.v-messages {
  font-size: 16px !important;
}
.v-messages__message {
  line-height: 20px !important;
}
</style>
