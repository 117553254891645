var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "480px", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("div", { staticClass: "title--text" }, [
                _vm._v("Xác nhận tài khoản")
              ]),
              _c("v-spacer"),
              _c(
                "v-icon",
                {
                  staticClass: "mr-n1",
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("mdi-close")]
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "pa-6" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "text-subtitle-1 pb-8" }, [
                    _vm._v(" Số điện thoại của bạn là "),
                    _c("strong", [_vm._v(_vm._s(this.user.parentPhone))])
                  ]),
                  _c("div", { staticClass: "text-subtitle-1" }, [
                    _vm._v(" Nhập mã OTP được gửi tới điện thoại bạn "),
                    _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")])
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      placeholder: "Nhập mã OTP tại đây",
                      name: "login",
                      rules: [_vm.$rules.required, _vm.$rules.otp],
                      type: "text",
                      color: "primary",
                      outlined: "",
                      "validate-on-blur": ""
                    },
                    model: {
                      value: _vm.otp,
                      callback: function($$v) {
                        _vm.otp = $$v
                      },
                      expression: "otp"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "white--text text-none text-subtitle-1 btn-text mt-4",
                  staticStyle: { width: "100%" },
                  attrs: {
                    depressed: "",
                    "x-large": "",
                    color: "primary",
                    loading: _vm.loading,
                    disabled: _vm.countdownLockConfirm > 0
                  },
                  on: {
                    click: function($event) {
                      return _vm.submit()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.getConfirmCountdown) + " ")]
              ),
              _c("div", { staticClass: "text-subtitle-1 pt-12 pb-6" }, [
                _c("span", { staticClass: "error--text" }, [_vm._v("(*)")]),
                _vm._v(
                  " Phụ huynh vui lòng kiểm tra lại số điện thoại nếu chưa nhận được tin nhắn chứa mã OTP từ Nhà trường. "
                )
              ]),
              _c(
                "v-btn",
                {
                  staticClass:
                    "primary--text text-none text-subtitle-1 font-weight-bold btn-text mt-2",
                  staticStyle: { width: "100%" },
                  attrs: {
                    depressed: "",
                    plain: "",
                    loading: _vm.loading,
                    disabled: _vm.countdownRegisterOTP > 0
                  },
                  on: {
                    click: function($event) {
                      return _vm.resendOTP()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.getSendCountdown) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }